import { DateTime } from "luxon";

const blog4 = {
  open: true,
  date: {
    day: 20,
    month: "Srpanj",
  },

  image: "./images/blog8.png",
  title: "Pilates kao kondicija za mozak",
  subtitle: "Svjesno vježbanje",
  postDate: "20.7.2022",
  luxonDate: DateTime.fromObject({ day: 20, month: 7, year: 2022 }),
  author: "Sanja Koprivec",
  content: [
    {
      type: "image",
      url: "./images/blog8.png",
      align: "center",
      width: "400px",
      height: "500px",
    },
    {
      type: "p",
      allign: "left",
      text: `Vježbanje je vrlo fokusiran, a time i misaoni proces kroz koji prolazi osjećaj napetosti u tijelu namjernim izazivanjem kontrakcije mišića.

        Pravilnom, fokusiranom i s namjerom izvedenom vježbom stimuliramo vezu između uma i tijela kojom pomažemo tijelu da osim što izvodi pokret, povećava svjesnost i kontrolu nad istim, korigira ga i ubrzava naše rezultate i zadane ciljeve.
`,
    },
    {
      type: "p",
      allign: "left",
      text: `U Pilatesu stalno treba razmišljati o položaju nogu i stopala, zdjelice, o aktivaciji trbuha i položaju leđa, ramenog koša i glave, a uz sve to treba i ritmički disati. Zato su pokreti kontrolirani i fokusirani, a sa vremenom veza tijela i uma postaje automatska i posve autonomna. U pitanju je proces, borba uma i tijela koja prelazi u igru uma i tijela.
        `,
    },
    {
      type: "p",
      allign: "left",
      text: `
        Kao i sve drugo u životu što učimo i ponavljamo, i pokret na taj način postaje sve točniji, kontroliraniji i elegantniji, a vježbanje sve lakše i zabavnije.
        
        Posljedično, izvan toga, hodamo kroz život „uspravnih leđa i držimo glavu gore”.
        
        Imajte hobi, imajte izazov pred sobom, bavite se Pilatesom.`,
    },
  ],
};

export default blog4;
