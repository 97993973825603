import * as React from "react";

import "../assets/scss/main.scss";
import "../assets/animation.css";

import HomeSix from "../components/HomeVideoDark";
import { Helmet } from "react-helmet";

// markup
const IndexPage = () => {
  return (
    <>
      <Helmet>
        <title>{"Pilates Studio Oblique"}</title>
        <html lang="hr" />
        <meta
          name="description"
          content={
            "Pilates Studio Oblique by Sanja Koprivec - kondicija za mozak i tijelo za sve dobne skupine! Pronađite nas na lokaciji: Industrijska ulica 28, Pribislavec, 40 000 Čakovec"
          }
        />
        <meta
          name="url"
          property="og:url"
          content={"https//:www.oblique-pilates.com"}
        />
      </Helmet>

      <HomeSix />
    </>
  );
};

export default IndexPage;
