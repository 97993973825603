import React from "react";
import Sanja from "./Sanja";

const About = () => {
  return (
    <>
      <div className="row align-items-center justify-content-center">
        <div className="col-lg-6 my-3">
          <div className="img-box dark-img-box">
            <img src="/images/sanjaprofile.jpg" alt="Sanja Koprivec" />
          </div>
        </div>

        <div className="col-lg-6 my-3">
          <div className="typo-box about-me">
            <h3>Sanja Koprivec</h3>
            <h5>
              Certificirana<span className="color-theme"> Pilates</span>
              Instruktorica
            </h5>
            <p>
              Veliki sam pobornik Pilates vježbi i vjerujem u sve što ova metoda
              vježbanja predstavlja i preporučam je svima. Garantiram svojim
              klijentima sve prednosti Pilates principa vježbanja i stojim iza
              uvjerenja da će iz mog studija „Oblique” izaći zdravi, jači,
              mobilniji i funkcionalniji, sa izvrsnom posturom i sa većim
              osjećajem samopouzdanja. Naučiti će vještinu vježbanja koju će
              moći primijeniti samostalno, svugdje i zauvijek. Ovo je posao
              kojim ću se baviti do duboke starosti! Dođite i pridružite mi se!
            </p>
            <div className="row about-list">
              <div className="col-md-12">
                <div className="media">
                  <label>Iskustvo</label>
                  <p>10 godina</p>
                </div>
                <div className="media">
                  <label>Certifikati</label>
                  <p>Profesionalna Pilates Instruktorica</p>
                </div>

                <div className="media">
                  <label>E-mail</label>
                  <p>info@oblique-pilates.com</p>
                </div>
                <div className="media">
                  <label>Mobitel</label>
                  <p>097 699 2799</p>
                </div>
              </div>
              {/* End .col */}
            </div>
            {/* End .row */}
            <div className="btn-bar">
              <a className="px-btn px-btn-theme">
                <Sanja />
              </a>
            </div>
          </div>
          {/* End .about me */}
        </div>
        {/* End .col */}
      </div>
    </>
  );
};

export default About;
