import { DateTime } from "luxon";

const blog14 = {
  open: true,
  date: {
    day: 15,
    month: "Veljača",
  },
  image: "./images/blog14.jpeg",
  title: "ZAŠTO SMO NAZVALI PILATES STUDIO <OBLIQUE> ",
  subtitle: "i što predstavlja logo...",
  postDate: "16.2.2023",
  luxonDate: DateTime.fromObject({ day: 15, month: 2, year: 2023 }),
  author: "Sanja Koprivec",
  content: [
    {
      type: "p",
      text: ` Latinski "obliquus" ili francuski "oblique" su bočni, abdomeni mišići ( unutarnji i vanjski ) koji pokrivaju veći dio našeg struka i protežu se, u koso, od rebra do zdjelice.`,
    },
    {
      type: "p",
      text: `Naš LOGO prikazuje TORZO na kojem su, u ljubičastom, ocrtani upravo OBLIQUE mišići.`,
    },
    {
      type: "p",
      text: `Kako "oblique" čitamo na hrvatskom jeziku "oblik", povezali smo mišić sa oblikom i oblikovanjem tijela vjezbanjem, a kako obliques mišiće stalno koristimo u pilatesu, nastao je;`,
    },
    {
      type: "h3",
      text: `PILATES STUDIO OBLIQUE!`,
    },
    {
      type: "p",
      text: `Oba mišića, unutarnji i vanjski obliques, suportiraju interne organe i mišiće kralježnice, čuvaju vitalne organe od ozljeda, pomažu da stojimo uspravno, te pomoću njih rotiramo i savijamo torzo.`,
    },
  ],
};

export default blog14;
