import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function SimpleSlider() {
  var settings = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    margin: 30,
    autoplaySpeed: 6000,
    responsive: [
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const TestimonilContent = [
    {
      imageName: "team-1",
      desc: `  Najlijepši prostor u Čakovcu za vježbanje i ugodan ambijent!`,
      reviewerName: "Ivana P.",
      rating: "5.0",
    },
    {
      imageName: "team-2",
      desc: `  Odlična pozicija za upiti jutarnje zrake sunca i rastegnuti tijelo (i um). Osjećam se ko nova, preporučam svima, nećete požaliti!`,
      reviewerName: "Mateja I.",
      rating: "5.0",
    },
    {
      imageName: "team-3",
      desc: `  Nakon par treninga počela sam osjećati mišiće za koje nisam ni znala da imam. Predivnih sat vremena prolazi u trenu uz ugodnu glazbu, usmjeravanje instruktorice i naravno, dobro društvo! Sve preporuke!`,
      reviewerName: "Maja L.",
      rating: "5.0",
    },
    {
      imageName: "team-4",
      desc: `  Stručna, simpatična i energična trenerica, ugodna atmosfera i veliki izbor termina!
      Vidljiv je napredak na tijelu:))`,
      reviewerName: "Josipa B.",
      rating: "5.0",
    },
    {
      imageName: "team-5",
      desc: `  Trening je odličan, dobro se naradiš 😉, a voditeljica je kvalitetna, dobro raspoložena i strpljiva❤️. Ekipa je super,i definitivno preporučam..`,
      reviewerName: "Magdalena K.",
      rating: "5.0",
    },
    {
      imageName: "team-6",
      desc: `  Nakon dulje pauze i nekoliko neuspješnih pokušaja da se pokrenem, po preporuci sam došla u Studio Oblique i napokon ponovo počela vježbati. Pilates baš kakvom sam se nadala! Vježbe su inovativne- uvijek novi izazovi bez dosadnih ponavljanja serija, Sanja je stručna, vodi računa o svakom detalju i odličan je motivator. Prostor je lijep, ugodan i čist. Preporuka od srca!`,
      reviewerName: "Lidija D.",
      rating: "5.0",
    },
    {
      imageName: "team-7",
      desc: `  Odlična atmosfera, profesionalna instruktorica, tople preporuke za sve koji se žele pokrenuti 🙂 👍💪`,
      reviewerName: "Ina Z.",
      rating: "5.0",
    },
    {
      imageName: "team-7",
      desc: ` Velik, uredan i ugodan prostor za vježbanje sa veeelikim ogledalima i ugodnom glazbom ❤️❤️
      Instruktorica je izvrsa ⭐️⭐️⭐️⭐️⭐️, a termina ima na izobilje.`,
      reviewerName: "Anita H.",
      rating: "5.0",
    },
    {
      imageName: "team-7",
      desc: ` Super treninzi, rekviziti se mijenjaju svaki tjedan tako da nikad nije dosadno. Ima puno termina pa svatko može odabrati kad mu najbolje odgovara. Trenerica je odlična, uvijek dobro raspoložena i strpljiva. 👍❤️
      `,
      reviewerName: "Marija R.",
      rating: "5.0",
    },
    {
      imageName: "team-7",
      desc: ` Vrhunska trenerica, na treninzima odlična atmosfera. Veliki izbor termina za odabrati. Preporučam svima koji brinu o sebi i svom zdravlju!
      `,
      reviewerName: "Snježana N.",
      rating: "5.0",
    },
  ];

  return (
    <>
      <Slider {...settings}>
        {TestimonilContent.map((val, i) => (
          <div className="testmonial-box media" key={i}>
            {/* End avatar */}
            <div className="testmonial-text media-body">
              <p>{val.desc}</p>
              <div className="testmonial-info">
                <h6>{val.reviewerName}</h6>
                <div className="rating-value"></div>
              </div>
            </div>
            {/* End testimonial-text */}
          </div>
        ))}
      </Slider>
    </>
  );
}
