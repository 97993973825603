import React from "react";
import Slider from "react-slick";

export default function SimpleSlider() {
  var settings = {
    dots: false,

    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    margin: 20,
    touchMove: true,
    fade: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const TestimonilContent = [
    {
      imageName: "carousel/1.jpg",
    },
    {
      imageName: "carousel/2.jpg",
    },
    {
      imageName: "carousel/3.jpg",
    },
    {
      imageName: "carousel/4.jpg",
    },
    {
      imageName: "carousel/5.jpg",
    },
    {
      imageName: "carousel/6.jpg",
    },
    {
      imageName: "carousel/7.jpg",
    },
    {
      imageName: "carousel/8.jpg",
    },
    {
      imageName: "carousel/9.jpg",
    },
    {
      imageName: "carousel/10.jpg",
    },
    {
      imageName: "carousel/11.jpg",
    },
    {
      imageName: "carousel/12.jpg",
    },
    {
      imageName: "carousel/13.jpg",
    },
    {
      imageName: "carousel/14.jpg",
    },
    {
      imageName: "carousel/15.jpg",
    },
    {
      imageName: "carousel/16.jpg",
    },
  ];

  return (
    <>
      <Slider {...settings}>
        {TestimonilContent.map((val, i) => (
          <div className="carousel-container" key={i}>
            <img className="carousel" src={val.imageName}></img>
          </div>
        ))}
      </Slider>
    </>
  );
}
