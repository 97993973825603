import { DateTime } from "luxon";

const blog5 = {
  open: true,
  date: {
    day: 12,
    month: "Prosinac",
  },

  image: "./images/blog7.png",
  title: "Smanjenje ili održavanje željene tjelesne težine",
  subtitle: "Najdjelotvornije uz sveukupno stvaranje i životnog stila i cilja",
  postDate: "12.12.2022",
  luxonDate: DateTime.fromObject({ day: 12, month: 12, year: 2022 }),
  author: "Sanja Koprivec",
  content: [
    {
      type: "p",
      align: "left",
      text: `1. uravnotežena prehrana ( proteini, masti, ugljikohidrati, vlakna, vitamini, minerali...)`,
    },
    {
      type: "p",
      allign: "left",
      text: ` 2. jedi doručak, ručak i večeru i ne više poslije 19h uvečer`,
    },
    {
      type: "p",
      allign: "left",
      text: `  3. sva hrana je dobra ako se umjereno konzumira`,
    },
    {
      type: "p",
      allign: "left",
      text: `   4. hrana je gorivo za tijelo i trećinu potrošimo u stanju mirovanja, dok ostalo služi za kretanje`,
    },
    {
      type: "p",
      allign: "left",
      text: `   5. kaolorije unutra - kalorije van; sve što ne potrošimo ide u zalihe`,
    },
    {
      type: "p",
      allign: "left",
      text: ` 6. prosječan unos kalorija je 2.500 – 3.000kcl dnevno za žene i 3000 – 3500 za muškarce`,
    },
    {
      type: "p",
      allign: "left",
      text: `  7. nekad ćeš pojesti više, nekada manje, u redu je`,
    },
    {
      type: "p",
      allign: "left",
      text: ` 8. kada više pojedeš nego ti je potrebno i zaredaju se dani, odredi dane za kompenziranje i pojedi manje`,
    },

    {
      type: "p",
      allign: "left",
      text: `   9. izbjegavaj hranu u kasne večeri`,
    },

    {
      type: "p",
      allign: "left",
      text: ` 10. preumjerenost jedenja najbolje je svladati redom i samodisciplinom, vlastitom voljom, nego posebnom dijetom
                            `,
    },
    {
      type: "p",
      allign: "left",
      text: `  11. obrati pozornost kako se osjećaj dok se prenajedeš, koliko si sam sebi težak i trom!`,
    },

    {
      type: "p",
      allign: "left",
      text: `   12. osjećaj i slušaj vlastito tijelo, kako se osjećaš nakon svog obroka, koja ti hrana odgovara i daje energije, a koja te umara tjelesno i umno`,
    },
    {
      type: "p",
      allign: "left",
      text: `   13. kako se preporuča zdrava hrana, tako se preporuča i najzdraviji napitak; voda.`,
    },
    {
      type: "p",
      allign: "left",
      text: `  14. nedvojbeno; popij barem 2 - 2,5l vode kroz dan`,
    },
    {
      type: "p",
      allign: "left",
      text: `   15. uključi aktivnu šetnja na svježem zraku barem 20min na dan`,
    },
    {
      type: "p",
      allign: "left",
      text: `   16. imaj konkretnu fizičku aktivnost 2 sata do 3 sata kroz tjedan`,
    },

    {
      type: "p",
      allign: "left",
      text: `  17. nađi aktivnost u kojoj uživaš ili si oduvijek htio savladati`,
    },
    {
      type: "p",
      allign: "left",
      text: `   18. zaista je najbolji je osjećaj kada odtreniraš i kada ti se nije dalo trenirati`,
    },
    {
      type: "p",
      allign: "left",
      text: `   19. ako voliš jesti, uvijek ćeš moći više pojesti uz vježbanje, a izgradnjom mišića podižeš svoj bazalni metabolizam`,
    },
    {
      type: "p",
      allign: "left",
      text: `   20. Spavanje je osnovni način regeneriranja uma i tijela, preporuča se san od 7 – 8 sati`,
    },
    {
      type: "p",
      allign: "left",
      text: `    21. budi uvijek svjestan da tvoja idealna težina znači veću pokretljivost, što je bitno za dug i kvalitetan život`,
    },
    {
      type: "p",
      allign: "left",
      text: `   22. sam prepoznaj i kontroliraj svoje impulzivno ponašanje, uči i stvaraj individualno korisne navike`,
    },

    {
      type: "p",
      allign: "left",
      text: `     23. disciplina je važan dio tvog osobnog karaktera`,
    },
    {
      type: "p",
      allign: "left",
      text: `  24. zdravi lokomotorni sustav utječe na tvoje sveopće zdravlje, njeguj svoju fizičku pokretljivost`,
    },
    {},
    {
      type: "quote",
      text: [
        {
          type: "p",
          text: `Usvajanje novih rutina ne smije biti forsirano, i u redu je zapesti, i opet nastaviti, no najvažnije se truditi disciplinirati i mijenjati svoju kvalitetu življenja malo po malo, na bolje!`,
        },
        {
          type: "p",
          text: `Provedi svoju tjednu fizički aktivnu rutinu

            u Pilates Studiju Oblique!`,
        },
      ],
    },
  ],
};

export default blog5;
