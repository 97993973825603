import { DateTime } from "luxon";

const blog3 = {
  open: true,
  date: {
    day: 7,
    month: "Srpanj",
  },

  image: "./images/blog9-3.jpeg",
  title: "Što nas pokreće u životu?",
  subtitle: "Zdravi zglobovi, jake kosti i mišići",
  postDate: "7.7.2022",
  luxonDate: DateTime.fromObject({ day: 7, month: 7, year: 2022 }),
  author: "Sanja Koprivec",
  content: [
    {
      type: "image",
      url: "./images/blog9-1.jpeg",

      width: "400px",
      height: "300px",

      align: "left",
    },
    {
      type: "p",
      align: "left",
      text: `Solidan mišić ravnomjerno raspoređen po cijelom tijelu, zdravo srce i kralježnica, mobilnost, balans, fleksibilnost, potrebni su za održavanje našeg tijela.

        Zapravo smo stalno u pokretu ( a opet toliko ograničeni ili u krivom izvođenju), i čini se da uopće ne cijenimo anatomiju tijela i kretanja i uzimamo svoje tijelo zdravo za gotovo.`,
    },
    {
      type: "image",
      url: "./images/blog9-2.jpeg",

      width: "400px",
      height: "300px",
    },
    {
      type: "p",
      align: "left",
      text: `Budite fizički aktivni, tjelesno snažni, zdravi i samostalni i u dubokoj starosti.

        Nemojte se ograničavati na puko hodanje naprijed, sjedeći i ležeći položaj, koristite svoje tijelo i njegov potencijal.`,
    },
    {
      type: "image",
      url: "./images/blog9-3.jpeg",

      width: "400px",
      height: "300px",
    },
    {
      type: "p",
      align: "left",
      text: `
        Povežite se sa svojim tijelom umno, izgradite ga i oblikujte, imajte kontrolu nad njime, isplati se.

        Osjećati ćete se bolje zdravstveno, emocionalno i kognitivno. Sve će Vam u životu biti lakše.`,
    },
    {
      type: "image",
      url: "./images/blog9-4.jpeg",

      width: "400px",
      height: "300px",
    },
    {
      type: "p",
      align: "left",
      text: `
        Pojačaj svoje motoričke sposobnosti, održavaj fleksibilnost i zdravlje svoje kralježnice kroz dinamičan i izazovan Pilates trening!

Ljudsko tijelo ima sposobnost da se izvija, savija, previja u svim smjerovima...kako ti koristiš svoje tijelo na svakidašnjoj bazi?`,
    },
    {
      type: "link",
      link: "https://www.picktime.com/termini",
      align: "left",
      text: `
        Pridruži nam se i rezerviraj svoj trening već danas!`,
    },
  ],
};

export default blog3;
