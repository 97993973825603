import { DateTime } from "luxon";

const blog9 = {
  open: true,
  date: {
    day: 18,
    month: "Listopad",
  },
  image: "./images/blog1.jpeg",
  title: "Što je Pilates?",
  subtitle: "Čemu služi pilates i zašto ga vježbati",
  postDate: "18.10.2021",
  luxonDate: DateTime.fromObject({ day: 18, month: 10, year: 2021 }),
  author: "Sanja Koprivec",
  content: [
    {
      type: "p",
      text: `Pilates je metoda definiranih i propisanih vježbi
        podijeljenih u tri razine; početni, srednji, napredni, što
        omogućuje postepen ulazak u Pilates svijet. Vježba se od
        niskog do visokog intenziteta ovisno o stupnju
        uvježbanosti klijenata. Pilates ponajviše služi za zaštitu
        i unapređenje zdravlja, prevenciju ozljeda i preuranjenog
        starenja i pogodan je za sve tipove tijela, sve uzraste i
        spolove. Učinak treninga je vidljiv po jačanju cijelog
        tijela, naročito trbušnjaka i jačanju "jezgre", odnosno
        trupa ( eng. Core ) - ili "power house" kako ga je nazvao
        Joseph Pilates ( utemeljitelj Pilates metode ) – mišići
        centra kojeg čine abdominalna muskulatura, muskulatura
        donjih leđa i glutealna muskulatura.`,
    },
    {
      type: "p",
      text: `Pilates se vježba uz sporo, kontrolirano i precizno
        kretanje kako bi ojačali duboki stabilizirajući mišići na
        vratu i ramenu, kralježnici i zdjelici, što je posebno
        važno jer upravo ti duboki mišići podupiru kralježnicu.
        Kada navedeni mišići nisu uvježbani, posljedica je bol u
        donjem dijelu leđa ili oko zdjelice. Pilates vježbe pomažu
        našem tijelu da bolje funkcionira i učinit će ga manje
        sklonim ozljedama. Pilates se preporuča i kao vježbanje
        nakon fizioterapije i rehabilitacije te je izvrsna metoda
        „kondicije za mozak“, vođen osnovnim principima metode;
        disanje, koncentracija, centriranje, kontrola, preciznost
        i fluidnost. Pravilnim i punim udisajem i izdisajem
        povećava se sveopće zdravlje stimulirajući krvotok i
        cirkulaciju, te rad unutrašnjih organa, posebno srca i
        jetre te je ujedno vrlo ugodan, ali i izazovan i
        dinamičan. Trening se radi u manjim grupama sa ciljem da
        se kontrolira i prati vježbanje klijenata.`,
    },
    {
      type: "quote",
      text: [
        {
          type: "h2",
          text: "Kada mogu očekivati promjene na sebi?",
        },
        {
          type: "p",
          text: `Konkretna istraživanja i iskustva pokazuju da se pomoću
            Pilates vježbi 60 minuta (2 do 3 puta tjedno), kroz 2 do
            6 mjeseci mogu očekivati sljedeća poboljšanja:`,
        },
        {
          type: "ul",
          text: [
            `povećana izdržljivost mišića trbuha, donjeg dijela
                leđa i gornjeg dijela tijela.`,
            `poboljšana fleksibilnost u tetivama, ramenima i
                gornjim i donjim dijelovima leđa,`,
            `značajno poboljšano frontalno poravnanje ramena i
                sagitalno poravnanje glave i zdjelice`,
            `značajno poboljšanje u statičkoj i dinamičkoj
                ravnoteži, funkcionalnoj pokretljivosti, i aktivno
                poboljšanje prilikom kretanja donjih ekstremiteta`,
            `povećava se mineralna gustoća kostiju naročito u
                lumbalnoj regiji, značajno smanjuje intenzitet boli,`,
            `ubrzava se bazični metabolizam`,
            `uživa se u boljoj kvaliteti života,`,
          ],
        },
      ],
    },
    {
      type: "p",
      text: `Cilj mi je približiti ljudima Pilates na dva načina: kao
        posebno učinkovitu metodu vježbanja za zdravu kralježnicu,
        za prevenciju od ozljeda i kao rehabilitacijsku metodu; te
        kao vještinu koju klijenti mogu prakticirati i napredovati
        kao pravi atleti.`,
    },
  ],
};

export default blog9;
