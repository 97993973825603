import React, { useState } from "react";
import Modal from "react-modal";

const Impressum = () => {
  const [isOpen, setIsOpen] = useState(false);

  function toggleModalOne() {
    setIsOpen(!isOpen);
  }

  return (
    <>
      <a onClick={toggleModalOne}>Impressum</a>

      {/* Start Modal for Blog-1 */}
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModalOne}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div>
          <button className="close-modal" onClick={toggleModalOne}>
            <img src="/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner">
            <div className="scrollable">
              <div className="blog-grid">
                {/* End blog-img */}
                <article className="article">
                  <div className="article-title">
                    <h2>Impressum</h2>
                  </div>
                  {/* End .article-title */}

                  <div className="article-content">
                    <h2>Pilates Studio Oblique j.d.o.o.</h2>
                    <h3>Sjedište</h3>
                    <ul>
                      <li>
                        Industrijska ulica 28, Pribislavec, 40 000 Čakovec
                      </li>
                      <li>OIB: 66961181069</li>
                      <li>MB: 05468256</li>
                      <li>Kontakt: +385 (0)97 699 2799</li>
                    </ul>
                    <h3>Banka</h3>
                    <ul>
                      <li>Privredna banka Zagreb d.d.</li>
                      <li>Broj računa: (IBAN) HR 40 2340 0091 1111 52648</li>
                      <li>SWIFT/BIC Code: PBZGHR2X</li>
                    </ul>
                    <h3>Trgovački sud</h3>
                    <ul>
                      <li>Trgovački sud u Varaždinu</li>
                      <li>Tt-21/3628-2</li>
                      <li>MBS:070186639 </li>

                      <li>Temeljni kapital od 10,00kn uplaćen u cjelosti. </li>
                      <li>Članovi uprave društva: Sanja Koprivec, direktor</li>
                    </ul>
                  </div>
                  {/* End article content */}
                </article>
                {/* End Article */}
              </div>
            </div>
          </div>
        </div>
        {/* End modal box news */}
      </Modal>
      {/* End  Modal for Blog-1 */}
    </>
  );
};

export default Impressum;
