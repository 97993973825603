import { DateTime } from "luxon";

const blog15 = {
  open: true,
  date: {
    day: 30,
    month: "Listopad",
  },
  image: "./images/2godisnjica.jpg",
  title: "Proslava 2 godišnjice",
  subtitle: "Pilates Studija Oblique",
  postDate: "30.10.2023",
  luxonDate: DateTime.fromObject({ day: 30, month: 10, year: 2023 }),
  author: "Sanja Koprivec",
  content: [
    {
      type: "p",
      text: ` OBILJEŽILI SMO 2. GODINU POSTOJANJA 💖 `,
    },
    {
      type: "p",
      text: `Hvala Svima koji su nam se pridružili, lijepo smo se podružili! `,
    },
    {
      type: "p",
      text: `Još jedanput, VELIKO VELIKO HVALA SVIM KLIJENTICAMA Pilates Studija Oblique `,
    },
    {
      type: "h3",
      text: `Kao što sam rekla, Vi ste mi inspiracija;
      Cilj mi je učiniti Vaše treniranje boljim i naprednijim iskustvom sa svakom zajedničkom godinom 💪💓🤸‍♀️! Nadam se da ću ispunjavati Vaša očekivanja još desetljećima `,
    },
    {
      type: "p",
      text: `Hvala Svima, Sanja`,
    },
  ],
};

export default blog15;
