import React, { useState } from "react";
import Scrollspy from "react-scrollspy";

const Header = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 71) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  const isBrowser = typeof window !== "undefined";

  if (isBrowser) {
    window.addEventListener("scroll", changeBackground);
  }

  return (
    <>
      {/* Header */}
      <header className={navbar ? "main-header fixed-header" : "main-header"}>
        <nav className="container">
          <div className=" header-transparent">
            <a to="/">
              <h3 className="logo-text logoOblique">Oblique</h3>
            </a>

            <button
              className="navbar-toggler"
              type="button"
              onClick={handleClick}
            >
              <div className={click ? "hamburger active" : "hamburger"}>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </button>

            <div
              className={
                click
                  ? "mobile-menu navbar-collapse justify-content-end active"
                  : "mobile-menu navbar-collapse justify-content-end"
              }
            >
              <a to="#s" className="sidebar-logo">
                <h3 className="logo-text logoOblique">Oblique</h3>
              </a>
              <Scrollspy
                className="anchor_nav navbar-nav ml-auto"
                items={[
                  "home",
                  "termini",
                  "prices",
                  "work",
                  "pictures",
                  "blog",
                  "novosti",
                  "contact",
                ]}
                currentClassName="current"
                offset={-71}
              >
                <li>
                  <a className="nav-link" href="#home" onClick={handleClick}>
                    <span>Početna</span>
                  </a>
                </li>

                <li>
                  <a className="nav-link" href="#termini" onClick={handleClick}>
                    <span>Termini</span>
                  </a>
                </li>
                <li>
                  <a className="nav-link" href="#prices" onClick={handleClick}>
                    <span>Cjenik</span>
                  </a>
                </li>
                <li>
                  <a className="nav-link" href="#work" onClick={handleClick}>
                    <span>Vježbe</span>
                  </a>
                </li>
                <li>
                  <a
                    className="nav-link"
                    href="#pictures"
                    onClick={handleClick}
                  >
                    <span>Galerija</span>
                  </a>
                </li>
                <li>
                  <a className="nav-link" href="#blog" onClick={handleClick}>
                    <span>Blog</span>
                  </a>
                </li>
                <li>
                  <a className="nav-link" href="#novosti" onClick={handleClick}>
                    <span>Novosti</span>
                  </a>
                </li>
                <li>
                  <a className="nav-link" href="#contact" onClick={handleClick}>
                    <span>Kontakt</span>
                  </a>
                </li>
              </Scrollspy>
              <button
                className=" sidebar-bottom-closer"
                type="button"
                onClick={handleClick}
              >
                Zatvori
              </button>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
