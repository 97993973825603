import { DateTime } from "luxon";

const blog11 = {
  open: true,
  date: {
    day: 7,
    month: "Studeni",
  },
  image: "./images/blog3.jpeg",
  title: "Principi Pilatesa",
  subtitle: "Korisni savjeti za vježbanje",
  postDate: "7.11.2021",
  luxonDate: DateTime.fromObject({ day: 7, month: 11, year: 2021 }),
  author: "Sanja Koprivec",
  content: [
    {
      type: "quote",
      text: [
        {
          type: "ul",
          text: [
            `CENTRIRANJE – svaka vježba ima početnu poziciju iz koje kreće pokret, no bez aktiviranja centra tijela ( trupa - trbuh, donji dio leđa, stražnjica I zdjelica ) pokret nije moguće držati precizno pod kontrolom`,
          ],
        },
        {
          type: "ul",
          text: [
            `KONTROLA – pratimo I kontroliramo svaki pokret tijela od početka do kraja vježbe`,
          ],
        },
        {
          type: "ul",
          text: [
            `KONCENTRACIJA - vježbe odrađujemo sa punom pažnjom uma I izvodimo ih sa fokusom da bi smo pokret odradili što efikasnije`,
          ],
        },
        {
          type: "ul",
          text: [
            `PRECIZNOST – pravilna tehnička izvedba vježbe I potpunost pokreta izuzetno su važne I kod najmanjeg pokreta`,
          ],
        },
        {
          type: "ul",
          text: [
            `FLUIDNOST – mekoća pokreta , gracioznost I opuštenost važni su za protok energije kroz cijelo tijelo`,
          ],
        },
      ],
    },
    {
      type: "p",
      text: ` Uvijek najprije zauzimamo pravilnu početnu poziciju i tek onda krećemo u vježbu, te je tako i završavamo.

        Ne paničarite ukoliko „krivo” dišete. Varijante su moguće. Najvažnije je disati ritmički. U nekim vježbama možemo i mijenjati dinamiku disanja. Generalno pravilo, udahnite u pripremi, ne možete početi vježbu bez uzimanja zraka.
        
        Ne izvodite vježbe kod kojih se ne osjećate ugodno, naročito ako se radi o oštroj boli. Ako vježba nije ugodna ili izaziva bol, tada to nije vježba za vas. Pri tome se ne misli na osjećaj laganog zatezanja.`,
    },
    {
      type: "p",
      text: ` Koristite pauze kada god vam je potrebno.
        Ni kod jedne vrste vježbe nije preporučljivo vježbati nakon obroka. Imajte razmak od najmanje sat i pol prije početka treninga.
        
        Bose noge najbolje će vas povezati s podom, međutim, zbog klizanja i higijenskih razloga, većina studija preporuča vježbanje u čarapama.
        
        Preporuča se uska ugodna sportska odjeća, kako bi se vidio pokret i držanje Vašeg tijela tokom izođenja vježbi. Kosa, ako je duga, treba biti svezana na način da ne smeta, naročito pri ležanju na leđima.
        
        Porazgovarajte s liječnikom kako bi bili sigurni da vam ove vježbe odgovaraju. Obratite pozornost na modificirane vježbe i prestanite raditi ako osjetite bol ili nelagodu. Ako niste sigurni, izbjegavajte određenu vježbu.`,
    },
  ],
};

export default blog11;
