import React from "react";
import Header from "./Header";
import Slider from "./SliderFiveVideoDark";
import About from "./AboutSix";
import Service from "./Service";
import Skills from "./Skills";
import ServicePrices from "./ServiicePrices";
import Portfolio from "./Portfolio";
import Testimonial from "./Testimonial";
import Carousel from "./Carousel";
import Blog from "./Blog";
import Footer from "./Footer";
import { withPrefix } from "gatsby";
import MineContact from "./MineContact";
import Gallery from "./Gallery";

const HomeSix = () => {
  return (
    <div className="home-dark">
      <Header />
      <Slider />
      <section id="about" className="section about-section after-left-section">
        <div className="container">
          <About />
        </div>
      </section>
      <section id="services" className="section services-section groupimg-bg">
        <div className="container">
          <div className="row justify-content-center section-title text-center">
            <div className="col-lg-7">
              <h3 className="font-alt">Kondicija za mozak i tijelo!</h3>
              <p style={{ color: "white" }}>
                Ako vam je kralježnica ukočena sa 30 godina, stari ste. Ako ste
                fleksibilni sa 60 godina, mladi ste. ~ Joseph H. Pilates
              </p>
            </div>
          </div>
          <Service />
        </div>
      </section>
      <section className="section skill-section gray-bg" id="termini">
        <div className="container">
          <div className="row justify-content-center section-title text-center">
            <div className="col-lg-7">
              <h3 className="font-alt">Termini treninga</h3>
            </div>
          </div>

          <Skills />
        </div>
      </section>
      <section id="prices" className="section about-section bcg-img">
        <div className="container">
          <div className="row justify-content-center section-title text-center">
            <div className="col-lg-7">
              <h3 className="font-alt">Cjenik treninga</h3>
            </div>
          </div>
          <ServicePrices />
        </div>
      </section>
      <section id="work" className="section gray-bg">
        <div className="container">
          <div className="row justify-content-center section-title text-center">
            <div className="col-lg-7">
              <h3 className="font-alt">Pilates vježbe</h3>
              <p>
                Pilates se postepeno prilagođava vašim mogućnostima i potrebama.
                Pravilno odrađivanje vježbi ključno je u postizanju rezultata.
              </p>
            </div>
          </div>
          <Portfolio />
        </div>
      </section>
      <div className="section testimonial" id="pictures">
        <div
          style={{ marginLeft: "1px", marginRight: "1px", maxWidth: "100%" }}
          className="container"
        >
          <div className="row justify-content-center section-title text-center">
            <div className="col-lg-7">
              <h3 className="font-alt">Galerija slika</h3>
            </div>
          </div>
          <Gallery />
        </div>
      </div>
      <section className="section after-left-section">
        <div className="container">
          <div className="row align-items-center justify-content-center section-title">
            <div className="col-lg-6 my-3">
              {" "}
              <Carousel />
            </div>
            <div className="col-lg-6 my-3 align-items-center justify-content-center svg-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="60"
                height="60"
                fill="#6e63d3"
                className="bi bi-balloon-heart-fill "
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M8.49 10.92C19.412 3.382 11.28-2.387 8 .986 4.719-2.387-3.413 3.382 7.51 10.92l-.234.468a.25.25 0 1 0 .448.224l.04-.08c.009.17.024.315.051.45.068.344.208.622.448 1.102l.013.028c.212.422.182.85.05 1.246-.135.402-.366.751-.534 1.003a.25.25 0 0 0 .416.278l.004-.007c.166-.248.431-.646.588-1.115.16-.479.212-1.051-.076-1.629-.258-.515-.365-.732-.419-1.004a2.376 2.376 0 0 1-.037-.289l.008.017a.25.25 0 1 0 .448-.224l-.235-.468ZM6.726 1.269c-1.167-.61-2.8-.142-3.454 1.135-.237.463-.36 1.08-.202 1.85.055.27.467.197.527-.071.285-1.256 1.177-2.462 2.989-2.528.234-.008.348-.278.14-.386Z"
                />
              </svg>

              <h3 className="font-alt">Proslava prve godišnjice</h3>
              <p className="white-text">
                Drage klijentice, od srca ❤ Vam hvala na podršci i obilježavanju
                godišnjice Pilates studija Oblique i divnoj atmosferi!
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="blog" className="section gray-bg">
        <div className="container">
          <div className="row justify-content-center section-title text-center">
            <div className="col-lg-7">
              <h3 className="font-alt">Blog</h3>
              <p>
                "No citizen has a right to be an amateur in the matter of
                physical training. ...What a disgrace it is for a man to grow
                old without ever seeing the strenght of which his body is
                capable" - Socrates
              </p>
            </div>
          </div>
          <Blog />
        </div>
      </section>
      <div className="section testimonial">
        <div className="container">
          <div className="row justify-content-center section-title text-center">
            <div className="col-lg-7">
              <h3 className="font-alt">Iskustva i dojmovi</h3>
            </div>
          </div>
          <Testimonial />
        </div>
      </div>

      <div className="section testimonial gray-bg" id="novosti">
        <div className="container ">
          <div className="row justify-content-left section-title text-left">
            <div className="col-lg-6  my-3">
              <h3 className="font-alt text-center">Novosti</h3>
              <p>
                Zapratite našu Facebook stranicu i budite u korak s najnovijim
                informacijama i događajima u Pilates studiju Oblique.
              </p>
              <br></br>
              <p>
                Podjelite svoja iskustva, umrežite se s ljubiteljima pilatesa i
                podržite našu malu Oblique zajednicu! Možete pročitati savjete,
                upute i zanimljivosti o pilatesu, te pronaći inspirativne
                materijale za uspješni trening i postizanje Vaših ciljeva u
                vježbanju.
              </p>
            </div>
            <div className="col-lg-5">
              <iframe
                src="https://www.facebook.com/plugins/likebox.php?href=http%3A%2F%2Fwww.facebook.com%2Fpilatesoblique&width=480&colorscheme=light&show_faces=true&border_color&stream=true&header=true"
                style={{
                  border: "none",
                  overflow: "hidden",
                  width: "480px",
                  height: "430px",
                  background: "white",
                }}
                allowtransparency="true"
                frameBorder="0"
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      <section id="contact" className="section after-left-section">
        <div className="container">
          <div className="row align-items-center">
            <MineContact />
          </div>
        </div>
      </section>
      <footer className="footer white">
        <div className="container">
          <Footer />
        </div>
      </footer>
    </div>
  );
};

export default HomeSix;
