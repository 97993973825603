import React, { useState } from "react";
import Modal from "react-modal";

const Terms = () => {
  const [isOpen, setIsOpen] = useState(false);

  function toggleModalOne() {
    setIsOpen(!isOpen);
  }

  return (
    <>
      <a onClick={toggleModalOne}>Politika privatnosti i kolačića</a>

      {/* Start Modal for Blog-1 */}
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModalOne}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div>
          <button className="close-modal" onClick={toggleModalOne}>
            <img src="/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner">
            <div className="scrollable">
              <div className="blog-grid">
                {/* End blog-img */}
                <article className="article">
                  <div className="article-title">
                    <h2>Politika privatnosti i politika kolačića </h2>
                  </div>
                  {/* End .article-title */}

                  <div className="article-content">
                    <h2>Politika kolačića</h2>
                    <p>
                      Kako bi internetska stranica radila ispravno te kako bi ju
                      se moglo unaprjeđivati u svrhu poboljšanja Vašeg iskustva
                      pregledavanja, potrebno je minimalnu količinu informacija
                      (cookies) spremiti na Vaše računalo. Više od 90%
                      internetskih stranica koristi praksu kolačića te su po
                      pravilima Euopske unije obvezne zatražiti pristanak
                      korisnika. Korištenjem ove internetske stranice pristajete
                      na uporabu kolačića, čijim blokiranjem i dalje možete
                      pregledavati stranicu, no neke njezine mogućnosti neće
                      biti funkcionalne.
                    </p>
                    <h3>Što je kolačić? </h3>
                    <p>
                      Kolačić je informacija spremljena na osobno računalo u
                      trenutku pregledavanja internetske stranice koju ste
                      posjetili. Kolačići omogućuju jednostavnije korištenje
                      budući da spremaju Vaše postavke za internetsku stranicu
                      (jezik ili adresa) te ih pri ponovnom pokretanju iste
                      iznova aktiviraju. Na taj način informacije su prilagođene
                      Vašim potrebama i uobičajenim načinima korištenja.Od
                      jednostavnih informacija o postavkama, kolačići mogu
                      spremiti i velik broj osobnih informacija (ime, e-mail
                      adresa) za koje Vi u potpunosti morate dati pristup.
                      Ukoliko im Vi to niste omogućili, utoliko kolačići ne mogu
                      pristupiti datotekama na Vašem računalu. Aktivnosti
                      spremanja i slanja kolačića Vama nisu vidljive, međutim
                      možete u postavkama izabranog internetskog preglednika
                      odabrati mogućnost odobravanja/odbijanja zahtjeva za
                      spremanje kolačića, brisanje spremljenih kolačića i druge
                      aktivnosti vezane uz korištenje kolačića.{" "}
                    </p>
                    <h3>Kako onemogućiti kolačiće?</h3>
                    <p>
                      {" "}
                      Isključivanjem kolačića ne dopuštate pohranjivanje istih
                      na vlastitom računalu. Postavke kolačića mogu se
                      konfigurirati i mijenjati u izabranom internetskom
                      pregledniku. Kako biste vidjeli postavke, odaberite
                      preglednik koji koristite (Chrome, Firefox, Internet
                      Explorer 9, Internet Explorer 7 i 8 te Operu ili Safari
                      (stranice na engleskom jeziku)). Ukoliko onemogućite
                      kolačiće, utoliko nećete biti u mogućnosti koristiti
                      određene funkcionalnosti internetskih stranica.{" "}
                    </p>
                    <h3> Što su privremeni kolačići?</h3>
                    <p>
                      Privremeni kolačići, ili kolačići sesije, uklanjanju se s
                      vašeg osobnog računala u trenutku zatvaranja internetskog
                      preglednika u kojem ste pregledavali internetsku stranicu.
                      Uz pomoć ovih kolačića, stranice pohranjuju privremene
                      podatke.{" "}
                    </p>
                    <h3> Što su stalni kolačići? </h3>{" "}
                    <p>
                      Stalni, ili spremljeni, kolačići ostaju na Vašem osobnom
                      računalu i nakon zatvaranja internetskog preglednika. Uz
                      pomoć ovih kolačića internetske stranice pohranjuju
                      podatke kako bi Vam se olakšalo korištenje. Primjerice,
                      internetske stranice koje zahtijevaju unos korisničkog
                      imena i lozinke ”zapamtit” će vaš unos koji će se
                      pojavljivati pri svakom novom posjetu istim. Stalni
                      kolačići ostat će zabilježeni na računalu danima,
                      mjesecima ili godinama.{" "}
                    </p>
                    <h3> Što su kolačići od prve strane? </h3>
                    <p>
                      Kolačići od prve strane dolaze s internetske stranice koju
                      pregledavate, a mogu biti privremeni ili stalni. Na taj
                      način, internetske stranice pohranjuju podatke koji će Vam
                      olakšati korištenje pri svakom novom posjetu toj
                      internetskoj stranici.{" "}
                    </p>
                    <h3>Što su kolačići treće strane? </h3>{" "}
                    <p>
                      Kolačići treće strane na Vaše računalo dolaze s drugih web
                      mjesta koje se nalaze na internetskoj stranici koju
                      pregledavate. Riječ je o primjerice pop-up oglasima, a
                      kolačići tu imaju ulogu praćenja internetskih stranica u
                      oglašivačke svrhe.{" "}
                    </p>
                    <h2>Politika privatnosti</h2>
                    <p>
                      Sadržaj ovih internet stranica je isključivo informativne
                      prirode te se informacije ne smatraju obvezujućima.
                      Pilates Studio Oblique ne preuzima odgovornost za cijene,
                      točnost i potpunost informacija, kao ni eventualnu štetu
                      nastalu kao posljedica e-mail komunikacije ili korištenjem
                      ovih stranica.Posjetitelji koriste ovu stranicu na
                      vlastitu odgovornost.Ova stranica je zaštićena autorskim
                      pravima te je zabranjena izmjena, kopiranje ili
                      distribucija bez pismene suglasnosti. Osobne podatke na
                      našoj Internet stranici NE prikupljamo. Internet stranica
                      www.oblique-pilates sadrži linkove koji vode na stranice
                      drugih pravnih ili privatnih osoba koje imaju različita
                      pravila privatnosti, stoga nismo odgovorni za informacije
                      koje se prikupljaju na njihovim stranicama i pravila
                      privatnosti, njihovu zakonitost i točnost.Imate pravo
                      zahtijevati informacije o Vašim osobnim podacima koje smo
                      primili te poslati zahtjev o tome koje vaše podatke imamo
                      te brisanje ili izmjenu svojih podataka na e-poštu
                      info@oblique-pilates.com. Ukoliko imate dodatnih pitanja
                      molimo Vas da nas kontaktirate na info@oblique-pilates.com
                    </p>
                  </div>
                  {/* End article content */}
                </article>
                {/* End Article */}
              </div>
            </div>
          </div>
        </div>
        {/* End modal box news */}
      </Modal>
      {/* End  Modal for Blog-1 */}
    </>
  );
};

export default Terms;
