import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";

const tabList = ["Prikaži 3 vježbe", "Prikaži sve vježbe"];

// const BrandingPortfolioContent = [
//   {
//     img: "S-istezanje",
//     title: "Istezanje",
//     subTitle: "Početno",
//     alterText: "Istezanje",
//     portfolioLink:
//       "https://play.google.com/store/apps/details?id=com.alightcreative.motion&hl=en&gl=US",
//   },
//   {
//     img: "S-istezanjejednenoge",
//     title: "Istezanje jedne noge",
//     subTitle: "Početno",
//     alterText: "Istezanje jedne noge",
//     portfolioLink:
//       "https://dribbble.com/shots/16529350-Nairo-Personal-Portfolio-React-Template",
//   },
//   {
//     img: "S-istezanjeobjenoge",
//     title: "Istezanje obje noge",
//     subTitle: "Početno",
//     alterText: "Istezanje obje noge",
//     portfolioLink:
//       "https://dribbble.com/shots/16529407-Deski-Saas-Software-React-Template",
//   },
//   {
//     img: "S-pocetna pozicija",
//     title: "Početna pozicija",
//     subTitle: "Početno",
//     alterText: "Početna pozicija",
//     portfolioLink:
//       "https://dribbble.com/shots/16545341-Imroz-Vue-JS-Creative-Agency-Portfolio-Template",
//   },
//   {
//     img: "S-loptica",
//     title: "Loptica",
//     subTitle: "Početno",
//     alterText: "Loptica",
//     portfolioLink:
//       "https://dribbble.com/shots/16529158-Waxon-Personal-Portfolio-VueJS-Template-RTL",
//   },
//   {
//     img: "S-stotica",
//     title: "Stotica",
//     subTitle: "Početno",
//     alterText: "Stotica",
//     portfolioLink:
//       "https://dribbble.com/shots/16529226-Krozo-Personal-Portfolio-React-Template",
//   },
// ];

const PhotographyPortfolioContent = [
  {
    img: "M-mostnaramenima",
    title: "Most na ramenima",
    subTitle: "Srednje zahtjevno",
    alterText: "Most na ramenima",
    portfolioLink:
      "https://dribbble.com/shots/16529158-Waxon-Personal-Portfolio-VueJS-Template-RTL",
  },
  {
    img: "M-dogbird",
    title: "Dog-Bird",
    subTitle: "Srednje zahtjevno",
    alterText: "Dog-Bird",
    portfolioLink:
      "https://dribbble.com/shots/16529407-Deski-Saas-Software-React-Template",
  },
  {
    img: "M-obrnutiplank",
    title: "Obrnuti plank",
    subTitle: "Srednje zahtjevno",
    alterText: "Obrnuti plank",
    portfolioLink:
      "https://dribbble.com/shots/16529407-Deski-Saas-Software-React-Template",
  },
];
const AllPortfolioContent = [
  {
    img: "S-istezanje",
    title: "Istezanje",
    subTitle: "Početno",
    alterText: "Istezanje",
    portfolioLink:
      "https://play.google.com/store/apps/details?id=com.alightcreative.motion&hl=en&gl=US",
  },
  {
    img: "S-istezanjejednenoge",
    title: "Istezanje jedne noge",
    subTitle: "Početno",
    alterText: "Istezanje jedne noge",
    portfolioLink:
      "https://dribbble.com/shots/16529350-Nairo-Personal-Portfolio-React-Template",
  },
  {
    img: "S-istezanjeobjenoge",
    title: "Istezanje obje noge",
    subTitle: "Početno",
    alterText: "Istezanje obje noge",
    portfolioLink:
      "https://dribbble.com/shots/16529407-Deski-Saas-Software-React-Template",
  },
  {
    img: "S-pocetna pozicija",
    title: "Početna pozicija",
    subTitle: "Početno",
    alterText: "Početna pozicija",
    portfolioLink:
      "https://dribbble.com/shots/16545341-Imroz-Vue-JS-Creative-Agency-Portfolio-Template",
  },
  {
    img: "S-loptica",
    title: "Loptica",
    subTitle: "Početno",
    alterText: "Loptica",
    portfolioLink:
      "https://dribbble.com/shots/16529158-Waxon-Personal-Portfolio-VueJS-Template-RTL",
  },
  {
    img: "S-stotica",
    title: "Stotica",
    subTitle: "Početno",
    alterText: "Stotica",
    portfolioLink:
      "https://dribbble.com/shots/16529226-Krozo-Personal-Portfolio-React-Template",
  },
  {
    img: "M-pila",
    title: "Pila",
    subTitle: "Srednje zahtjevno",
    alterText: "Pila",
    portfolioLink:
      "https://dribbble.com/shots/16529226-Krozo-Personal-Portfolio-React-Template",
  },

  {
    img: "M-mostnaramenima",
    title: "Most na ramenima",
    subTitle: "Srednje zahtjevno",
    alterText: "Most na ramenima",
    portfolioLink:
      "https://dribbble.com/shots/16529158-Waxon-Personal-Portfolio-VueJS-Template-RTL",
  },
  {
    img: "M-dogbird",
    title: "Dog-Bird",
    subTitle: "Srednje zahtjevno",
    alterText: "Dog-Bird",
    portfolioLink:
      "https://dribbble.com/shots/16529407-Deski-Saas-Software-React-Template",
  },
  {
    img: "L-svijeca",
    title: "Svijeća",
    subTitle: "Napredno",
    alterText: "Svijeća",
    portfolioLink:
      "https://dribbble.com/shots/16529339-Beny-Personal-Portfolio-React-Template",
  },
  {
    img: "L-sklopka",
    title: "Sklopka",
    subTitle: "Napredno",
    alterText: "Sklopka",
    portfolioLink:
      "https://dribbble.com/shots/16529339-Beny-Personal-Portfolio-React-Template",
  },
  {
    img: "L-skare",
    title: "Škare u mostu na ramenima",
    subTitle: "Napredno",
    alterText: "Škare u mostu na ramenima",
    portfolioLink:
      "https://dribbble.com/shots/16529339-Beny-Personal-Portfolio-React-Template",
  },

  {
    img: "L-odrolaj",
    title: "Odrolaj tijelo preko glave",
    subTitle: "Napredno",
    alterText: "Odrolaj tijelo preko glave",
    portfolioLink:
      "https://dribbble.com/shots/16529339-Beny-Personal-Portfolio-React-Template",
  },
];
// const FashionPortfolioContent = [
//   {
//     img: "L-svijeca",
//     title: "Svijeća",
//     subTitle: "Napredno",
//     alterText: "Svijeća",
//     portfolioLink:
//       "https://dribbble.com/shots/16529339-Beny-Personal-Portfolio-React-Template",
//   },
//   {
//     img: "L-sklopka",
//     title: "Sklopka",
//     subTitle: "Napredno",
//     alterText: "Sklopka",
//     portfolioLink:
//       "https://dribbble.com/shots/16529339-Beny-Personal-Portfolio-React-Template",
//   },
//   {
//     img: "L-skare",
//     title: "Škare u mostu na ramenima",
//     subTitle: "Napredno",
//     alterText: "Škare u mostu na ramenima",
//     portfolioLink:
//       "https://dribbble.com/shots/16529339-Beny-Personal-Portfolio-React-Template",
//   },
//   {
//     img: "L-skare",
//     title: "Škare u mostu na ramenima",
//     subTitle: "Napredno",
//     alterText: "Škare u mostu na ramenima",
//     portfolioLink:
//       "https://dribbble.com/shots/16529339-Beny-Personal-Portfolio-React-Template",
//   },
//   {
//     img: "L-odrolaj",
//     title: "Odrolaj tijelo preko glave",
//     subTitle: "Napredno",
//     alterText: "Odrolaj tijelo preko glave",
//     portfolioLink:
//       "https://dribbble.com/shots/16529339-Beny-Personal-Portfolio-React-Template",
//   },
// ];

const Portfolio = () => {
  return (
    <SimpleReactLightbox>
      <div className="positon-relative">
        <div className="portfolio-filter-01">
          <Tabs>
            <TabList className="filter d-flex justify-content-center">
              {tabList.map((val, i) => (
                <Tab key={i}>{val}</Tab>
              ))}
            </TabList>
            {/* End tablist */}

            <SRLWrapper>
              {/* <TabPanel>
                <div className="portfolio-content row lightbox-gallery">
                  {BrandingPortfolioContent.map((val, i) => (
                    <div
                      className="col-sm-6 col-lg-4 grid-item product"
                      key={i}
                    >
                      <div className="portfolio-box-01">
                        <div className="portfolio-img">
                          <div className="portfolio-info">
                            <h5>
                              <a href={val.portfolioLink} target="_blank">
                                {val.title}
                              </a>
                            </h5>
                            <span>{val.subTitle}</span>
                          </div>
                        
                          <a
                            href={`../../${val.img}.jpg`}
                            className="gallery-link"
                          >
                            <img
                              src={`../../${val.img}.jpg`}
                              alt={val.alterText}
                            />
                          </a>
                        

                         
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
       
              </TabPanel> */}

              <TabPanel>
                <div className="portfolio-content row lightbox-gallery">
                  {PhotographyPortfolioContent.map((val, i) => (
                    <div
                      className="col-sm-6 col-lg-4 grid-item product"
                      key={i}
                    >
                      <div className="portfolio-box-01">
                        <div className="portfolio-img">
                          <div className="portfolio-info">
                            <h5>
                              <a href={val.portfolioLink} target="_blank">
                                {val.title}
                              </a>
                            </h5>
                            Težina: <span>{val.subTitle}</span>
                          </div>
                          {/* End .portfolio-info */}
                          <a
                            href={`../../${val.img}.jpg`}
                            className="gallery-link"
                          >
                            <img
                              src={`../../${val.img}.jpg`}
                              alt={val.alterText}
                            />
                          </a>
                          {/* End gallery link */}

                          {/* End .portfolio-icon */}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {/* End list wrapper */}
              </TabPanel>
              <TabPanel>
                <div className="portfolio-content row lightbox-gallery">
                  {AllPortfolioContent.map((val, i) => (
                    <div
                      className="col-sm-6 col-lg-4 grid-item product"
                      key={i}
                    >
                      <div className="portfolio-box-01">
                        <div className="portfolio-img">
                          <div className="portfolio-info">
                            <h5>
                              <a href={val.portfolioLink} target="_blank">
                                {val.title}
                              </a>
                            </h5>
                            Težina: <span>{val.subTitle}</span>
                          </div>
                          {/* End .portfolio-info */}
                          <a
                            href={`../../${val.img}.jpg`}
                            className="gallery-link"
                          >
                            <img
                              src={`../../${val.img}.jpg`}
                              alt={val.alterText}
                            />
                          </a>
                          {/* End gallery link */}

                          {/* End .portfolio-icon */}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {/* End list wrapper */}
              </TabPanel>
              {/* 
              <TabPanel>
                <div className="portfolio-content row lightbox-gallery">
                  {FashionPortfolioContent.map((val, i) => (
                    <div
                      className="col-sm-6 col-lg-4 grid-item product"
                      key={i}
                    >
                      <div className="portfolio-box-01">
                        <div className="portfolio-img">
                          <div className="portfolio-info">
                            <h5>
                              <a href={val.portfolioLink} target="_blank">
                                {val.title}
                              </a>
                            </h5>
                            <span>{val.subTitle}</span>
                          </div>
                
                          <a
                            href={`../../${val.img}.jpg`}
                            className="gallery-link"
                          >
                            <img
                              src={`../../${val.img}.jpg`}
                              alt={val.alterText}
                            />
                          </a>
                        
                        </div>
                      </div>
                    </div>
                  ))}
               
                </div>
       
              </TabPanel> */}
            </SRLWrapper>
            {/* End tabpanel */}
          </Tabs>
        </div>
      </div>
    </SimpleReactLightbox>
  );
};

export default Portfolio;
