import React, { useEffect, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import axios from "axios";

const ServicePrices = () => {
  const [ServiceData, setServiceData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://api.veela.app/api/cms/external/getItemsDemo/",
          {
            devName: "prices_new",
          },
          {
            headers: {
              "Content-Type": "application/json",
              "tenant-auth":
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6InBpbGF0ZXNvYmxpcXVlIiwicGFzcyI6InBpbGF0ZXNvYmxpcXVlIiwiaWF0IjoxNzA0MTA5NzE2LCJleHAiOjE3MzUyMTM3MTZ9.trS4RY9gVQ3CvjI_9iE9bHsynvQd5XNr3AaiT1eXe_c",
            },
          }
        );

        const jsonStr = response.data.main[0]["cms_json.json"];
        const jsonData = JSON.parse(jsonStr);

        setServiceData(jsonData);
      } catch (error) {
        console.error("error", error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <div className="row gy-4">
        {ServiceData.map((val, i) => (
          <div key={val.no} className="col-sm-6 col-lg-4">
            <ScrollAnimation
              animateIn="fadeInUp"
              animateOut="fadeInOut"
              animateOnce={true}
              key={i}
            >
              <div className="feature-box-02">
                <div
                  className=""
                  style={{ color: "#6e63d3", fontSize: "1.5rem" }}
                >
                  <h2>
                    {val.priceEur}
                    <span style={{ fontSize: "1.5rem" }}> €</span>
                  </h2>
                </div>
                <h6>{val.service}</h6>
                <h5>{val.title}</h5>
                <p>{val.desc}</p>
              </div>
            </ScrollAnimation>
          </div>
        ))}
      </div>
      <div style={{ marginTop: "10px" }} className="row gy-4">
        <h4
          style={{
            textAlign: "center",
            font: "2rem bold",
            fontFamily: "inherit",
            color: "rgb(110, 99, 211)",
          }}
        >
          SVI TRENINZI SU U TRAJANJU OD 60 MIN
        </h4>
        <hr></hr>
        <span>
          Prilagodite treninge svom slobodnom vremenu. Osigurajte svoje mjesto
          putem poveznice (15 slobodnih mjesta u grupi) i u slučaju nemogućnosti
          dolaska, odjavite se najmanje 2 sata prije početka treninga.
        </span>
        <span>
          Neiskorištene treninge možete koristiti tijekom važeće mjesečne
          članarine ili s kupnjom nove bez prekida članstva. Međutim, kod
          individualnih članarina neiskorišteni treninzi se ne prenose.
          Produženje ili odgoda plaćanja članarine nije moguća.
        </span>
        <span>
          Potvrda članarine dolazi s računom nakon prvog posjeta probnom
          besplatnom treningu. Kada istekne vaša trenutačna članarina, nova se
          uspostavlja kupnjom nove, a potvrđuje se novim računom s datumom novog
          dolaska.
        </span>
      </div>
    </>
  );
};

export default ServicePrices;
