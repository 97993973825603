import { DateTime } from "luxon";

const blog6 = {
  open: true,
  date: {
    day: 6,
    month: "Prosinac",
  },

  image: "./images/blog6-1.jpeg",
  title: "Zdravlje nije potpuno bez tjelesne aktivnosti",
  subtitle: "Fizički, medicinski i kognitivno",
  postDate: "9.12.2022",
  luxonDate: DateTime.fromObject({ day: 9, month: 12, year: 2022 }),
  author: "Sanja Koprivec",
  content: [
    {
      type: "quote",
      text: [
        {
          type: "p",
          allign: "left",
          text: `Sa tjelesnom aktivnošću do potpunog zdravlja.
    
            Budimo zdravi, osjećajmo se bolje i izgledajmo bolje!`,
        },
        {
          type: "p",
          allign: "right",
          text: `uz Pilates Studio Oblique`,
        },
      ],
    },
    {
      type: "image",
      url: "./images/blog6-2.png",
    },
    {
      type: "image",
      url: "./images/blog6-3.png",
    },
    {
      type: "image",
      url: "./images/blog6-4.png",
    },
  ],
};

export default blog6;
