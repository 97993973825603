import { DateTime } from "luxon";

const blog10 = {
  open: true,
  date: {
    day: 10,
    month: "Studeni",
  },
  image: "./images/blog2.jpeg",
  title: "Što Pilates čini našem tijelu?",
  subtitle: "Zanimljivosti i činjenice o učinkovitosti vježbanja Pilatesa",
  postDate: "10.11.2021",
  luxonDate: DateTime.fromObject({ day: 10, month: 11, year: 2021 }),
  author: "Sanja Koprivec",
  content: [
    {
      type: "quote",
      text: [
        {
          type: "ul",
          text: [`Pilates ispravlja posturu i gradi uravnoteženo tijelo.`],
        },
      ],
    },
    {
      type: "p",
      text: ` Nadahnut je sveukupnom ravnotežom u tijelu, umu i duhu,
        no naglasak je na uravnoteženom razvoju mišića.
        Neravnoteža u tijelu može nastati kao rezultat genetike,
        posla kojim se ljudi bave ili sporta koji treniraju.
        Pilates je izvrstan alat za njihovo ispravljanje, jer
        repertoar uključuje jednostrane vježbe (što znači da
        trenirate jednu po jednu stranu). Ove vrste pokreta
        ističu - i pomažu ujednačavanju - bilo kakve asimetrije
        u tijelu.`,
    },
    {
      type: "p",
      text: ` Konkretna istraživanja i iskustva pokazuju da se sa
        Pilates vježbama 60 minuta 2 do 3 puta tjedno, kroz 2 do
        6 mjeseci, poboljšava izdržljivost mišića trbuha, donjeg
        dijela leđa i gornjeg dijela tijela, poboljšava
        fleksibilnost u tetivama, ramenima i gornjim i donjim
        dijelovima tijela. Značajno je poboljšano frontalno
        poravnanje ramena i sagitalno poravnanje glave i
        zdjelice značajno poboljšanje u statičkoj i dinamičkoj
        ravnoteži, funkcionalnoj pokretljivosti, i aktivno
        poboljšanje prilikom kretanja donjih ekstremiteta. Uz to
        se povećava i mineralna gustoća kostiju - naročito u
        lumbalnoj regiji,`,
    },
    {
      type: "quote",
      text: [
        {
          type: "ul",
          text: [
            ` Pilates značajno smanjuje intenzitet boli i ubrzava
      bazični metabolizam, što nam na kraju omogućuje bolju
      kvalitetu života!`,
          ],
        },
      ],
    },
  ],
};

export default blog10;
