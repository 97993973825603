import { DateTime } from "luxon";

const blog7 = {
  open: true,
  date: {
    day: 9,
    month: "Prosinac",
  },

  image: "./images/blog5.png",
  title: "Kako izgleda pilates trening",
  subtitle: "Pravila i savjeti za ugodan trening",
  postDate: "9.12.2022",
  luxonDate: DateTime.fromObject({ day: 9, month: 12, year: 2022 }),
  author: "Sanja Koprivec",
  content: [
    {
      type: "image",
      url: "./images/blog5.png",
    },
    {
      type: "h2",
      text: `GRUPNI TRENING – PILATES NA PROSTIRCI ( MAT PILATES )`,
    },
    {
      type: "p",
      text: `10 klijenata po treningu.

        Svatko je u svojoj fazi treniranja i radi koliko može, uz prilagođene lakše varijacije vježbe.`,
    },
    {
      type: "h2",
      text: `TRENING TRAJE 60 MINUTA`,
    },
    {
      type: "p",
      text: `Uključujući zagrijavanje i hlađenje treninzi traju 60 minuta.

        Učinak obično počinje padati nakon 45 minuta.
        
        Kraća sesija obično znači točan fokus i veći intenzitet.
        
        Svakim treningom ćete točno znati što i kako raditi.
        
        Za najbolje rezultate važno je vježbe izvršiti učinkovito.`,
    },
    {
      type: "h2",
      text: `TRENINZI MORAJU BITI INTENZIVNIJI`,
    },
    {
      type: "p",
      text: `Intenzitet je najvažnija varijabla za gubitak masti.

        Povećanje intenziteta znači da ne biste trebali moći razgovarati između vježbi.
        
        Održavanje razdoblja odmora kratkim i slatkim čini vaš trening kratkim i učinkovitim (i pomalo bolnim).`,
    },
    {
      type: "h2",
      text: `METODA TRENIRANJA`,
    },
    {
      type: "p",
      text: `Osim ako se ne trenira za natjecanje izdržljivosti na duge staze, sav propisan kardio trening je intervalni trening srednjeg do visokog intenziteta.

        To znači da izmjenjujemo intenzivna razdoblja rada s razdobljima odmora.
        
        Studije su pokazale da je HIIT oko 9 puta učinkovitiji za gubitak masnoće od tradicionalnog kardio vježbanja.
        
        Primjer HIIT-a bio bi rad od 65-85% vašeg maksimalnog napora 45 sekundi, i 15 sekundi odmora, ovisno o mogućnostima klijenata.
        
        Klasičnu vrstu pilates vježbanja odrađujemo kontinuraino, bez pauzi, vježba slijedi za vježbom, i nazivamo ga „Flow Pilates.”`,
    },
    {
      type: "h2",
      text: `VRSTE TRENINGA I REKVIZITI`,
    },
    {
      type: "p",
      text: `Treninzi su koncipirani kao vježba za cijelo tijelo.

        Vježba za cijelo tijelo sa naglašenim intenzitetom na pojedinom mišiću/mišićima ili motoričkoj sposobnosti.
        
        Mat Pilates je vježba koja kao težinu koristi vlastito tijelo.
        
        Koristimo rekvizite za dodatna opterećenja; male i velike lopte, trake, bučice, ciglice, štapove.`,
    },
  ],
};

export default blog7;
