import { DateTime } from "luxon";

const blog8 = {
  open: true,
  date: {
    day: 5,
    month: "Listopad",
  },
  image: "./images/blog4.jpeg",
  title: "Bolovi tijekom i nakon treninga",
  subtitle: "Pravila i savjeti za ugodan trening",
  postDate: "5.10.2022",
  luxonDate: DateTime.fromObject({ day: 5, month: 10, year: 2022 }),
  author: "Sanja Koprivec",
  content: [
    // {
    //   type: "h2",
    //   text: `Priprema za trening`,
    // },
    // {
    //   type: "p",
    //   text: `Ni kod jedne vrste vježbe nije preporučljivo vježbati nakon obroka. Imajte razmak od najmanje sat i pol prije početka treninga.`,
    // },
    // {
    //   type: "p",
    //   text: `Bose noge najbolje će vas povezati s podom; preporučamo vježbanje u čarapama.`,
    // },
    // {
    //   type: "p",
    //   text: `Preporuča se uska ugodna sportska odjeća, kako bi se vidio pokret i držanje Vašeg tijela tokom izođenja vježbi. Kosa, ako je duga, treba biti svezana na način da ne smeta, naročito pri ležanju na                                       leđima.   `,
    // },
    // {
    //   type: "p",
    //   text: `Zagrijavanje tijela i svih zglobova ključni je dio prevencije ozljeda i pripreme vašeg tijela za trening.                                                                                                                                                                                                                                                                                     Uvodimo se sa obrascima kretanja, te se pripremamo za zadatke koji nas očekuju.
    //   Uvijek najprije zauzimamo pravilnu početnu poziciju i tek onda krećemo u vježbu, te je tako i završavamo.`,
    // },
    // {
    //   type: "p",
    //   text: `U Pilatesu naglasak stavljamo na disanje. Ne paničarite ukoliko „krivo” dišete. Varijante su moguće. Najvažnije je disati ritmički. U nekim vježbama možemo i mijenjati dinamiku disanja. Generalno pravilo, udahnite u pripremi, ne možete početi vježbu bez uzimanja zraka.`,
    // },
    { type: "h2", text: `Sigurnost u toku treninga` },
    {
      type: "p",
      text: `Važno je da gradiš na vještini "slušanja svog tijela. `,
    },
    {
      type: "p",
      text: `Ne izvodite vježbe kod kojih se ne osjećate ugodno, naročito ako se radi o oštroj boli. Ako vježba nije ugodna ili izaziva bol, tada to nije vježba za vas. Pri tome se ne misli na osjećaj laganog zatezanja. `,
    },
    {
      type: "p",
      text: `Koristite pauze kada god vam je potrebno. `,
    },
    {
      type: "p",
      text: `Ako vam se nešto ne čini kako treba, nemojte forsirati; vaše tijelo vam obično pokušava nešto reći.
        Jaka bol u toku treninga – obično uzrokovana nepravilnim izvođenjem vježbe. 
        Ništa zapravo ne bi trebalo "boljeti.  `,
    },
    {
      type: "p",
      text: `Odmah me obavijestite o svim oštrim ili iznenadnim bolovima ili bilo kakvim neprirodnim osjećajima u zglobovima ili ligamentima. `,
    },
    {
      type: "p",
      text: `Porazgovarajte s liječnikom kako bi bili sigurni da vam ove vježbe odgovaraju.`,
    },
    {
      type: "quote",
      text: [
        {
          type: "h2",
          text: "Dobra bol protiv loše",
        },
        {
          type: "p",
          text: `Blaga nelagoda dio je procesa vježbanja i neophodna je za poboljšanje performansi i tjelesne građe.
            To je dobra bol. Trebala bi biti kratkotrajna i samo tijekom vježbe.
            Umor nakon treninga trebao bi vas učiniti aktivnim, ali ne i iscrpljenim. 
            `,
        },
        {
          type: "h2",
          text: "Muskofiber",
        },
        {
          type: "p",
          text: `Bol je uobičajena, osobito za mišiće koji nisu bili vježbani dulje vrijeme ili kada izvodite vježbu na koju niste navikli. 
            Bol obično počinje unutar nekoliko sati, ali doseže vrhunac dva dana nakon vježbanja. 
            To se naziva odgođena mišićna bol i normalno je kada započnete sa treniranjem pilatesa.                                                                        
            `,
        },
        {
          type: "h2",
          text: "Dosljednost",
        },
        {
          type: "p",
          text: `Ako želite vidjeti rezultate, morate raditi, i to često. 
            Možete imati najbolje treninge na svijetu, ali oni će biti neučinkoviti bez dosljednosti. 
            Visoka razina učestalosti osigurava dosljedno povišenu brzinu metabolizma, što znači da ćete sagorijevati kalorije i nakon što vaše tijelo prestane vježbati.                                                                        
            `,
        },
        {
          type: "h2",
          text: "Disciplina",
        },
        {
          type: "p",
          text: `Zaista ćete se najbolje osjećati one dane kada Vam se nije dalo vježbati, a ipak ste odradili trening.
            Budite odlučni, zadajte si ciljeve i budite kontinuirani u treninzima.`,
        },
        {
          type: "h2",
          text: "Oporavak",
        },
        {
          type: "p",
          text: `Vaše se tijelo mora oporaviti.                                                                                                                                                               
            Ako ste još uvijek bolni poslije treninga snage, za nekog će biti najbolje razmak između treninga snage od 48 sati.                                                                                                                      
            `,
        },
      ],
    },
  ],
};

export default blog8;
