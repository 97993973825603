import Blog1 from "./blog1";
import blog10 from "./blog10";
import blog11 from "./blog11";
import blog2 from "./blog2";
import blog3 from "./blog3";
import blog4 from "./blog4";
import blog5 from "./blog5";
import blog6 from "./blog6";
import blog7 from "./blog7";
import blog8 from "./blog8";
import blog9 from "./blog9";
import blog12 from "./blog12";
import blog13 from "./blog13";
import blog14 from "./blog14";
import blog15 from "./blog15";

import { DateTime } from "luxon";

const mainBlog = [
  Blog1,
  blog2,
  blog3,
  blog4,
  blog5,
  blog6,
  blog7,
  blog8,
  blog9,
  blog10,
  blog11,
  blog12,
  blog13,
  blog14,
  blog15,
];

export default mainBlog;
