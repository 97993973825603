import { Link } from "gatsby";
import React, { useState } from "react";
import Modal from "react-modal";

const Sanja = () => {
  const [isOpen, setIsOpen] = useState(false);

  function toggleModalOne() {
    setIsOpen(!isOpen);
  }

  return (
    <>
      <span onClick={toggleModalOne}>Saznaj više</span>

      {/* Start Modal for Blog-1 */}
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModalOne}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div>
          <button className="close-modal" onClick={toggleModalOne}>
            <img src="/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner">
            <div className="scrollable">
              <div className="blog-grid">
                {/* End blog-img */}
                <article className="article">
                  <div className="article-title">
                    <h2>O meni</h2>
                  </div>
                  {/* End .article-title */}

                  <div className="article-content">
                    <p>
                      Kako sam po prirodi ekstrovert i društveno otvorena i
                      pristupačna osoba uvijek sam voljela raditi u dinamičnim
                      okruženjima i komunicirati sa velikim brojem ljudi. Iza
                      mene je petnaestogodišnja bogata profesionalna karijera
                      koja se zasniva na menadžerskim pozicijama u dvije velike
                      kompanije i na kojoj sam iznimno zahvalna, ali koju sam
                      propitkivala zbog nedovoljno kreativnosti i statičnosti u
                      mom osobnom rastu. Okrenula sam se svojoj drugoj strasti
                      koja me je pratila kroz život, a to je moj hobi; sport i
                      rekreacija, odnosno, vježbanje Pilatesa. Aktivno sam
                      trenirala kroz cijeli život. Gimnastiku i balet u sklopu
                      gimnastike, atletiku i suvremeni ples i radila sa izvrsnim
                      trenerima.
                    </p>
                    <p>
                      Moje rano bavljenje sportom bitan je faktor u stvaranju
                      navike vježbanja, kako je to savršena disciplinska metoda
                      kojom se vježba socijalizacija i interakcija, fizički i
                      kognitivni razvoj djece, a općenito se razvija i takozvana
                      „mišićna memorija“ - oblik proceduralne memorije koja
                      uključuje „upisivanje“ određenog motoričkog zadatka u
                      memoriju principom „ponavljanja“, i s vremenom, za taj se
                      zadatak stvara dugotrajna mišićna memorija koja omogućuje
                      izvođenje pokreta uz sve manje ili nimalo svjesnog napora.
                    </p>

                    <p>
                      I nakon pripadanja određenom klubu disciplinirano i
                      rutinski sam nastavljala sa različitim vrstama tjelesne
                      aktivnosti. Privrženost i navika, te odlično naučena
                      tehnika omogućavala mi je kontinuirano samostalno
                      usavršavanje u rekreativnom i kreativnom procesu kroz sve
                      godine života, posebno se fokusirajući na Pilates. što je
                      za moj daljnji život imalo izuzetnu važnost u održavanju
                      zdravlja.
                    </p>

                    <p>
                      Shodno intenzivnom treniranju sportova i plesa, od
                      najranijih sam dana javno nastupala u natjecanjima i
                      plesnim predstavama, te iskusila i televizijske nastupe,
                      kao i iskustvo voditeljice na Čakovečkoj televiziji.
                    </p>
                    <p>
                      Zadnjih godina sam se posvetila usavršavanju u Pilates
                      disciplini i certifikaciji, proučavanju trendova vježbanja
                      na lokalnom i globalnom tržištu, dopunjavala svoje znanje
                      o metodama treniranja i organizaciji treninga, fizičkoj
                      kondiciji, proučila nebrojeno trenera i pristupa,
                      razmjenjivala iskustva sa drugima. Sa svojih 40 godina, u
                      najboljoj sam fizičkoj i kondicijskoj spremi i, iz toga
                      razloga, nadam se da ću i sama biti primjer i inspiracija
                      svojim vježbačima.{" "}
                    </p>
                    <p>
                      Veliki sam pobornik Pilates vježbi i vjerujem u sve što
                      ova metoda vježbanja predstavlja i preporučam je svima.
                      Garantiram svojim klijentima sve prednosti Pilates
                      principa vježbanja i stojim iza uvjerenja da će iz mog
                      studija „Oblique” izaći zdravi, jači, mobilniji i
                      funkcionalniji, sa izvrsnom posturom i sa većim osjećajem
                      samopouzdanja. Naučiti će vještinu vježbanja koju će moći
                      primijeniti samostalno, svugdje i zauvijek. Ovo je posao
                      kojim ću se baviti do duboke starosti! Dođite i pridružite
                      mi se!{" "}
                    </p>
                  </div>
                  {/* End article content */}
                </article>
                {/* End Article */}
              </div>
            </div>
          </div>
        </div>
        {/* End modal box news */}
      </Modal>
      {/* End  Modal for Blog-1 */}
    </>
  );
};

export default Sanja;
