import React from "react";

const ServiceContent = [
  {
    title: "Ojačaj kralježnicu i ispravi posturu",
  },
  {
    title: "Povećaj stabilnost i kontrolu, te propriocepciju",
  },
  {
    title: "Poboljšaj fleksibilnost i opseg pokreta cijelog tijela",
  },

  {
    title:
      "Oživi svoj lokomotorni sustav uz jaki trup (eng. core) kao središte Pilates vježbi",
  },
  {
    title: "Pojačaj mišićnu masu, simetriju, izdržljivost i ubrzaj metabolizam",
  },
  {
    title: "Dijeluj danas za bezbrižnu starost uz prevenciju bolova i ozljeda",
  },
];

export default function Service() {
  return (
    <>
      <div className="row">
        {ServiceContent.map((val, i) => (
          <div className="col-md-6 col-lg-4 my-3" key={i}>
            <div className="feature-box-01">
              <div className="feature-content">
                <h5>{val.title}</h5>
              </div>
            </div>
            {/* End .feature-box-01 */}
          </div>
        ))}
      </div>
    </>
  );
}
