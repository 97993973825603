import React from "react";

const MineContact = () => {
  return (
    <>
      <div className="col-lg-6 my-3">
        <div className="contct-form">
          <div className="sm-title">
            <h4 className="font-alt">Rezervirajte svoj termin</h4>
          </div>
          {/* End .sm-title */}
          <p>
            Pošaljite nam direktan upit putem e-pošte ili pregledajte samostalno
            slobodne termine klikom na gumb ispod.
            <br />
            <br /> Svoj dolazak možete najaviti tako da se zabilježite za neki
            termin, te će te na svoju adresu E-pošte ili SMS porukom dobiti
            obavjesti o rezervaciji ili promjenama vezanim uz taj termin. <br />
            <br />
          </p>
          <div className="btn-bar">
            <a
              className="px-btn px-btn-theme"
              href="https://www.picktime.com/termini"
              target="_blank"
            >
              Provjeri slobodne termine
            </a>
          </div>
        </div>
        {/* <div className="contct-form">
          <div className="sm-title">
            <h4 className="font-alt">
              Predstavite sebe i svoje ciljeve vježbanja
            </h4>
          </div>
         
          <p>
            {" "}
            Popunite upitnik kako bih Vas mogli pravilno usmjeriti prema Vašim
            osobnim ciljevima i potrebama. Upitnik je u potpunosti privatan i
            dane informacije se ne koriste u druge svrhe osim potrebe
            informiranja trenera.
          </p>
          <div className="btn-bar">
            <a
              className="px-btn px-btn-theme"
              href="https://forms.gle/cebqYPv313ENqX367
              "
              target="_blank"
            >
              Popuni upitnik
            </a>
          </div>
        </div> */}
      </div>
    

      <div className="col-lg-4 ml-auto my-3">
        <div className="contact-info">
          <div className="sm-title">
            <h4 className="font-alt">Kontakt podaci</h4>
            <p>
              Zainteresirani ste za trening ili poslovnu suradnju? Vaši upiti su
              dobrodošli!
            </p>
          </div>
          <div className="media">
            <div className="icon">
              <i className="ti-map"></i>
            </div>
            <span className="media-body">
              Industrijska 28, Pribislavec, <br />
              40 000 Čakovec, <br />
              (zgrada Directa, 1. kat)
            </span>
          </div>
          {/* End media */}

          <div className="media">
            <div className="icon">
              <i className="ti-email"></i>
            </div>
            <span className="media-body">
              Upiti i rezervacije: <br />
              info@oblique-pilates.com
            </span>
          </div>
          {/* End media */}

          <div className="media">
            <div className="icon">
              <i className="ti-mobile"></i>
            </div>
            <span className="media-body">+385 97 699 2799</span>
          </div>
          {/* End media */}
        </div>
      </div>
      {/* End .col */}
    </>
  );
};

export default MineContact;
