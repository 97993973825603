import { DateTime } from "luxon";

const blog12 = {
  open: true,
  date: {
    day: 1,
    month: "Sječanj",
  },
  image: "./images/blog12.png",
  title: "ZA NOVE POČETKE - STVARAMO NOVI MODERNI PILATES",
  subtitle: "SRETNA NOVA GODINA 2023. - SPREMNI SMO ZA NOVE IZAZOVE!",
  postDate: "1.1.2023",
  luxonDate: DateTime.fromObject({ day: 1, month: 1, year: 2023 }),
  author: "Sanja Koprivec",
  content: [
    {
      type: "p",
      text: `Svima,`,
    },
    {
      type: "p",
      text: ` veliko hvala što ste vježbali s Nama u 2022. godini!`,
    },
    {
      type: "p",
      text: `Želim Vam da se osjećate zdravo, zadovoljno i imate fantastičnu 2023. godinu!`,
    },
    {
      type: "p",
      text: `Kroz 2022. godinu, u Pilates Studio Oblique odradili smo 1200 treninga. Studijom je prošlo oko 130tak ljudi.`,
    },
    {
      type: "p",
      text: `Cilj nam je i dalje rušiti predrasude o Pilatesu i pokazati koliko je vježba dinamična i učinkovita za sveukupne tjelesne performanse.`,
    },
    {
      type: "h2",
      text: `ZA NOVE POČETKE – STVARAMO NOVI MODERNI PILATES`,
    },
    {
      type: "quote",
      text: [
        {
          type: "ul",
          text: [`Kako je rekao sam utemeljitelj Pilatesa, Joseph Pilates:`],
        },
        {
          type: "ul",
          text: [
            `„...dajem Vam vježbe, bazu, a na Vama instruktorima je da dalje mijenjate i poboljšavate ovu metodu vježbanja...”`,
          ],
        },
      ],
    },
    {
      type: "p",
      text: `To je upravo što radimo u Pilates Studiju Oblique.,`,
    },
    {
      type: "p",
      text: `Stvaramo "Novi Moderni Pilates", koji će Vas kao ženu; osnažiti, učiniti mobilnijom i fleksibilnijom, istaknuti gracioznost i elegantnost u Vašem svakodnevnom pokretu.`,
    },
    {
      type: "p",
      text: `Radimo za Vašu dobrobit i samopouzdanje`,
    },
    {
      type: "p",
      text: `Vidimo se ponovno sutra!`,
    },
    {
      type: "p",
      text: `Svi koji niste probali vježbati kod nas, slobodni ste rezervirati svoj prvi probni trening i uvjeriti se da je vježbanje u Studiju Oblique stvoreno za Vas!`,
    },
    {
      type: "link",
      url: "https://www.picktime.com/termini",
      text: `Rezerviraj svoj termin ovdje <-`,
    },
    {
      type: "p",
      text: `Sanja`,
    },
  ],
};

export default blog12;
