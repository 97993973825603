import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import ScrollAnimation from "react-animate-on-scroll";
import { Fade, Slide } from "react-reveal";

const tabList = ["Skraćeno", "Prikaži sve"];

const Gallery = () => {
  const pictureLoop = () => {
    const returnHtml = [];

    const maxNumber = 39;

    for (let i = 1; i <= maxNumber; i++) {
      returnHtml.push(
        <div className="col-sm-4 col-lg-2 grid-item " key={i}>
          <Fade bottom>
            <div className="portfolio-img container-gallery">
              {/* End .portfolio-info */}
              <a href={`./images/galerija/${i}.jpeg`} className="gallery-link">
                <img
                  className="img-galley"
                  src={`./images/galerija/${i}.jpeg`}
                />
              </a>
              {/* End gallery link */}

              {/* End .portfolio-icon */}
            </div>{" "}
          </Fade>
        </div>
      );
    }
    return returnHtml;
  };
  const pictureLoopMin = () => {
    const returnHtml = [];

    const maxNumber = 39;

    for (let i = 39; i > 27; i--) {
      returnHtml.push(
        <div className="col-sm-4 col-lg-2 grid-item " key={i}>
          <Fade up>
            <div className="portfolio-img container-gallery">
              {/* End .portfolio-info */}
              <a href={`./images/galerija/${i}.jpeg`} className="gallery-link">
                <img
                  className="img-galley"
                  src={`./images/galerija/${i}.jpeg`}
                />
              </a>
              {/* End gallery link */}

              {/* End .portfolio-icon */}
            </div>
          </Fade>
        </div>
      );
    }
    return returnHtml;
  };
  return (
    <SimpleReactLightbox>
      <div className="positon-relative">
        <div className="portfolio-filter-01">
          <Tabs>
            <TabList className="filter d-flex justify-content-center">
              {tabList.map((val, i) => (
                <Tab key={i}>{val}</Tab>
              ))}
            </TabList>
            {/* End tablist */}

            <SRLWrapper>
              <TabPanel>
                <div className="portfolio-content rowgallery">
                  {pictureLoopMin()}{" "}
                </div>{" "}
                {/* End list wrapper */}
              </TabPanel>
              <TabPanel>
                <div className="portfolio-content rowgallery">
                  {pictureLoop()}
                </div>
                {/* End list wrapper */}
              </TabPanel>
            </SRLWrapper>
            {/* End tabpanel */}
          </Tabs>
        </div>
      </div>
    </SimpleReactLightbox>
  );
};

export default Gallery;
