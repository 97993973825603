import { DateTime } from "luxon";

const blog2 = {
  open: true,
  date: {
    day: 21,
    month: "Prosinac",
  },

  image: "./images/blog10.png",
  title: "Vježbanje za dugovječnost",
  subtitle: "Pronađi pravi smisao u razvijanju navike vježbanja",
  postDate: "21.12.2022",
  luxonDate: DateTime.fromObject({ day: 21, month: 12, year: 2022 }),
  author: "Sanja Koprivec",
  content: [
    {
      type: "quote",
      text: [
        {
          type: "p",
          allign: "left",
          text: `VJEŽBAM za održavanje forme u vremenu komforta i sjedilačkog načina života`,
        },
        {
          type: "p",
          allign: "left",
          text: `VJEŽBAM za kompenzaciju prirode posla ( sjedeći, stojeći, česta putovanja, podizanje tereta ) i dominantno korištenje jedne strane tijela`,
        },
        {
          type: "p",
          allign: "left",
          text: `VJEŽBAM protiv ukočenosti i bolova u pojedinim dijelovima tijela

          ( vrat, kralježnica, prsni koš, lopatice, ramena i ruke, noge i stopala, koljena i kukovi )`,
        },
        {
          type: "p",
          allign: "left",
          text: `VJEŽBAM za izliječenje deformacija i dijagnoza, te za spriječavanje degenerativnih stanja`,
        },
        {
          type: "p",
          allign: "left",
          text: `VJEŽBAM za smanjenje ili održavanje željene težine`,
        },
        {
          type: "p",
          allign: "left",
          text: `VJEŽBAM za smanjenje stresa i poboljšanja raspoloženja; za dodatnu energije u danu`,
        },
        {
          type: "p",
          allign: "left",
          text: `VJEŽBAM za prevenciju preuranjenog starenja`,
        },
        {
          type: "p",
          allign: "left",
          text: `VJEŽBAM za fizičku neovisnost u starijoj dobi`,
        },
        {
          type: "p",
          allign: "left",
          text: `VJEŽBAM za sveukupnu kvalitetu življenja`,
        },
      ],
    },
    {
      type: "image",
      url: "./images/blog10-1.png",
    },
    {
      type: "image",
      url: "./images/blog10-2.png",
    },
  ],
};

export default blog2;
