import React, { useState } from "react";

import { DateTime } from "luxon";

const Blog1 = {
  open: true,
  date: {
    day: 12,
    month: "Prosinac",
  },

  image: "./images/blog11.png",
  title: "Međimurski Savez sportske rekreacije",
  subtitle: `Postala sam član saveza "Sport za sve"`,
  postDate: "12.12.2022",
  luxonDate: DateTime.fromObject({ day: 12, month: 12, year: 2022 }),
  author: "Sanja Koprivec",
  content: [
    {
      type: "h2",
      align: "center",
      text: `MEĐIMURSKI SAVEZ SPORTSKE REKREACIJE "SPORT ZA SVE" / MEĐIMURJE ALLIANCE OF SPORT RECREATION "SPORT FOR ALL"`,
    },

    {
      type: "p",
      text: (
        <>
          Pilates Studio Oblique postao je <b>član</b>
        </>
      ),
    },
    {
      type: "p",
      align: "center",
      text: (
        <>
          <i>
            Međimurski savez sportske rekreacije „Sport za sve“ je organizacija
            koja uključuje u sportsko rekreacijske djelatnosti sve strukture i
            dobne skupine građana, koji žele da im sportsko rekreacijski
            sadržaji postanu životna potreba i svakodnevna navika.{" "}
          </i>
        </>
      ),
    },

    {
      type: "p",
      text: `Zahvaljujem na uvodu u sadržaj i suradnji Gimnastičkom klubu „Marijan Zadravec - Macan, Čakovec, tajniku Marijanu Vugrinčiću i trenerici Mariji Jurak, te svim članovima MSSR-e na prihvaćanju Pilates Studija Oblique da budemo dio Vaše sportske inicijative. Želimo surađivati, izmjenjivati iskustva i raditi na sveopćem širenju sportskog duha.`,
    },
    {
      type: "p",
      text: `U Gimnastičkom klubu sam nekada i sama bila članica. Usadili su mi izvrsnu disciplinu i postavili temelje za moje cjeloživtno vježbanje.`,
    },
    {
      type: "link",
      link: "https://www.gimnastika-cakovec.hr",
      text: `Gimnastički klub "Marijan Zadravec Macan" `,
    },
    {
      type: "p",
      text: `Za sve dobne skupine i svačije potrebe, interese, hobije, opuštanje, zabavu i odlično društvo;

            MSSR ima ponuđeni rekreacijski sadržaj!
            
            Možete se pridružiti mnoštvu rekreativnih udruga, zajednica i društvenim događanjima. Svima predlažem da provjere za sebe i svoje najmilije gdje bi se mogli uklopiti i provesti kvalitetno slobodno vrijeme.`,
    },
    {
      type: "link",
      link: "https://mssr-sportzasve.hr",
      text: `Posjeti službenu stranicu MSSR "Sport za sve"`,
    },
    {
      type: "p",
      text: `Naše tijelo stvoreno je da se giba i zdravlje nije potpuno bez fizičke aktivnosti. Razlozi su fiziološki i neupitni, nemojmo zapostavljati brigu o tijelu i sveukupnom zdravlju.`,
    },
  ],
};

export default Blog1;
