import React from "react";

const Skills = () => {
  return (
    <>
      <div className="row">
        <div className="col-lg-4 my-3">
          <div className="sm-title">
            <h4 className="font-alt">Jutro PON-PET</h4>
          </div>
          <ul className="aducation-box theme-bg">
            <li>
              <span className="dark-bg">Jutarnji trening</span>
              <h6>9:00 - 10:00</h6>
              <p>Aktivan Pilates za dobar dan! </p>
            </li>
            {/* <li>
              <span className="dark-bg">Poslijepodnevni trening</span>
              <h6>16:00 - 17:00</h6>
              <p>Pilates fluidnost nakon posla! </p>
            </li>
            <li>
              <span className="dark-bg">Poslijepodnevni trening</span>
              <h6>17:20 - 18:20</h6>
              <p>Poslijepodnevno otpuštanje stresa uz Pilates!</p>
            </li>
            <li>
              <span className="dark-bg">Večernji trening</span>
              <h6>18:40 - 19:40</h6>
              <p>Večernji ritam uz dinamičan Pilates!</p>
            </li> */}
          </ul>
          {/* End  .aducation-box */}
        </div>
        {/* End .col */}

        <div className="col-lg-4 my-3">
          <div className="sm-title">
            <h4 className="font-alt">Poslijepodne PON-PET</h4>
          </div>
          <ul className="aducation-box dark-bg">
            {/* <li>
              <span className="theme-bg">Jutarnji trening</span>
              <h6>09:00 -10:00</h6>
              <p>Aktivan Pilates za dobar dan!</p>
            </li> */}
            <li>
              <span className="theme-bg">Poslijepodnevni trening</span>
              <h6>16:00 - 17:00</h6>
              <p>Pilates fluidnost nakon posla! </p>
            </li>
            <li>
              <span className="theme-bg">Poslijepodnevni trening</span>
              <h6>17:20 - 18:20</h6>
              <p>Poslijepodnevno otpuštanje stresa uz Pilates!</p>
            </li>
            <li>
              <span className="theme-bg">Večernji trening</span>
              <h6>18:40 - 19:40</h6>
              <p>Večernji ritam uz dinamičan Pilates!</p>
            </li>
            {/* <li>
              <span className="theme-bg">Večrenji trening</span>
              <h6>20:00 - 21:00</h6>
              <p>Izazovan i ugodan Pilates za kraj dana!</p>
            </li>*/}
          </ul>
          {/* End  .aducation-box */}
        </div>
        {/* End .col */}
        <div className="col-lg-4 my-3">
          <div className="sm-title">
            <h4 className="font-alt">Subota</h4>
          </div>
          <ul className="aducation-box theme-bg">
            <li>
              <span className="dark-bg">Jutarnji trening</span>
              <h6>9:00 - 10:00</h6>
              <p>Aktivan Pilates za dobar dan! </p>
            </li>
          </ul>
          {/* End  .aducation-box */}
        </div>
        {/* End .col */}
      </div>
    </>
  );
};

export default Skills;
