import { DateTime } from "luxon";

const blog13 = {
  open: true,
  date: {
    day: 15,
    month: "Sječanj",
  },
  image: "./images/blog13-1.jpg",
  title: "ZDRAVO TIJELO",
  subtitle: "Svaki čovjek morao bi moći osnovne kretnje...",
  postDate: "15.1.2023",
  luxonDate: DateTime.fromObject({ day: 15, month: 1, year: 2023 }),
  author: "Sanja Koprivec",
  content: [
    {
      type: "image",
      url: "./images/blog13.png",
    },
    {
      type: "h2",
      text: `Svaki čovjek morao bi moći osnovne kretnje...,`,
    },
    {
      type: "p",
      text: ` 1. STATI NA PRSTE`,
    },
    {
      type: "p",
      text: `2. DIGNUTI KOLJENO ( barem visina kuka )`,
    },
    {
      type: "p",
      text: `3. SAGNUTI SE UNAPRIJED RAVNIH LEĐA`,
    },
    {
      type: "p",
      text: `4. DODAKNUTI PRSTE NA NOGAMA`,
    },
    {
      type: "p",
      text: `5. SOLIDAN ČUČANJ`,
    },
    {
      type: "p",
      text: `6. ČUČANJ U ČUČNJU`,
    },
    {
      type: "quote",
      text: [
        {
          type: "ul",
          text: [`Što od navedenoga tvoje tijelo nije u stanju izvesti?`],
        },
        {
          type: "ul",
          text: [
            `Ne možeš stajati mirno na prstima, padaš ako digneš koljeno od poda...sagneš se kao „bakica”, ne možeš dotaknuti rukama do poda...noge jedva izdrže čučanj i misliš da je lakše čučnuti se...`,
          ],
        },
      ],
    },
    {
      type: "h2",
      text: `Potrebna ti je fizička aktivnost!`,
    },
    {
      type: "p",
      text: `Izvježbaj se od glave do pete`,
    },
    {
      type: "p",
      text: `u Pilates Studiu Oblique!`,
    },

    {
      type: "link",
      url: "https://www.picktime.com/termini",
      text: `Rezerviraj svoj termin ovdje <-`,
    },
  ],
};

export default blog13;
