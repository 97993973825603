import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import mainBlog2 from "../blogs/main";

const Blog = () => {
  const [isOpen, setIsOpen] = useState(false);

  const [selectedBlod, setSelectedBlog] = useState(undefined);

  const [openAll, setOpenAll] = useState();

  const [mainBlog, setMainBlog] = useState([]);

  function removeSelectedBlog() {
    setSelectedBlog(undefined);
  }

  function toggleModalOne() {
    setIsOpen(!isOpen);
  }

  useEffect(() => {
    const newMainBlog = mainBlog2.map((blog) => {
      const luxonDate = blog.luxonDate;

      return {
        ...blog,
        date: {
          ...blog.date,
          day: luxonDate.day,
          // month: luxonDate.month,
        },
        jsDate: luxonDate.toJSDate(),
      };
    });

    console.log("newMainBlog", newMainBlog);

    newMainBlog.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.jsDate) - new Date(a.jsDate);
    });

    console.log("newMainBlog", newMainBlog);

    setMainBlog(newMainBlog);
  }, []);

  const generateHTML = (content) => {
    let align = "";

    if (content.align === "center") {
      align = "text-center";
    }

    if (content.type === "p") {
      return <p className={`${align}`}>{content.text}</p>;
    }
    if (content.type === "h1") {
      return <h1 className={`${align}`}>{content.text}</h1>;
    }
    if (content.type === "h2") {
      return <h2 className={`${align}`}>{content.text}</h2>;
    }
    if (content.type === "h3") {
      return <h3 className={`${align}`}>{content.text}</h3>;
    }
    if (content.type === "h4") {
      return <h4 className={`${align}`}>{content.text}</h4>;
    }
    if (content.type === "h5") {
      return <h5 className={`${align}`}>{content.text}</h5>;
    }
    if (content.type === "h6") {
      return <h6 className={`${align}`}>{content.text}</h6>;
    }
    if (content.type === "image") {
      return (
        <div className={`${align}`}>
          <img
            src={content.url}
            width={content.width}
            height={content.height}
            alt={content.alt}
          ></img>
        </div>
      );
    }
    if (content.type === "link") {
      return (
        <a href={content.link} className={`${align}`}>
          {content.text}
        </a>
      );
    }
    if (content.type === "ul") {
      return (
        <ul>
          {content.text.map((text) => {
            return <li>{text}</li>;
          })}
        </ul>
      );
    }
    if (content.type === "quote") {
      return (
        <blockquote>
          {content.text.map((quoteContent) => {
            return generateHTML(quoteContent);
          })}
        </blockquote>
      );
    }
  };

  const [blogPagination, setBlogPagination] = useState(3);

  return (
    <>
      <div className="row">
        {mainBlog.map((json, index) => {
          if (index < blogPagination) {
            return (
              <div key={index} className="col-lg-4 col-md-6 my-3">
                <div
                  className="blog-post"
                  onClick={(c) => setSelectedBlog(json)}
                >
                  <div className="blog-img">
                    <div className="data">
                      <span>{json.date.day}</span>
                      <small>{json.date.month}</small>
                    </div>
                    <div style={{ width: "400px", height: "300px" }}>
                      <img
                        style={{
                          overflow: "hidden",
                          height: "auto",

                          minWidth: "400px",
                          objectFit: "stretch",
                        }}
                        src={json.image}
                        alt="blog post"
                      ></img>
                    </div>
                  </div>

                  <div className="blog-info">
                    <h6>{json.title}</h6>
                    <p>{json.subtitle}</p>
                  </div>
                </div>
              </div>
            );
          }
        })}

        {mainBlog.length > 3 && mainBlog.length > blogPagination ? (
          <div style={{ marginTop: "4em" }} className="text-center">
            <a
              onClick={(e) => {
                e.preventDefault();
                setBlogPagination(blogPagination + 3);
              }}
              className="px-btn px-btn-theme"
              href="#"
              target="_blank"
            >
              Pregledaj starije postove
            </a>
          </div>
        ) : (
          <></>
        )}
      </div>

      {/* Start Modal for Blog-1 */}
      <Modal
        isOpen={selectedBlod?.open}
        onRequestClose={(c) => removeSelectedBlog()}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        {selectedBlod && (
          <div>
            <button
              className="close-modal"
              onClick={(c) => removeSelectedBlog()}
            >
              <img src="/cancel.svg" alt="close icon" />
            </button>
            {/* End close icon */}

            <div className="box_inner">
              <div className="scrollable">
                <div className="blog-grid">
                  {/* End blog-img */}
                  <article className="article">
                    <div className="article-title">
                      <h2>{selectedBlod.title}</h2>
                      <div className="media">
                        <div className="media-body">
                          <label> {selectedBlod.author}</label>
                          <span>{selectedBlod.postDate}</span>
                        </div>
                      </div>
                    </div>
                    {/* End .article-title */}

                    <div className="article-content">
                      {selectedBlod.content.map((content) => {
                        return generateHTML(content);
                      })}
                    </div>
                    {/* End article content */}
                  </article>
                  {/* End Article */}
                </div>
              </div>
            </div>
          </div>
        )}
        {/* End modal box news */}
      </Modal>
      {/* End  Modal for Blog-1 */}
    </>
  );
};

export default Blog;
