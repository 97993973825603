import React from "react";
import TextLoop from "react-text-loop";
import { FaAngleDown } from "react-icons/fa";
import Social from "./Social";
import { videoTagString, VideoTag } from "react-video-tag";

import vvvideo from "./pilatesvideo.mp4";

const Slider = () => {
  return (
    <>
      <section
        id="home"
        className="home-banner overlay overlay-50 inner-content-static video-banner video-banner-dark "
        style={{
          backgroundColor: "black",
        }}
      >
        <div className="container">
          <div className="row align-items-center full-screen">
            <div className="col-lg-12">
              <div className="hb-typo text-center">
                <img
                  src="./images/logo/Oblique_logoweb.svg"
                  width="80%"
                />
                {/* <h1 className="font-alt">Pilates Studio Oblique</h1>*/}
                <h2 className="mb-4">
                  <TextLoop>
                    <span className="loop-text">Ugodna atmosfera</span>
                    <span className="loop-text">Za sve tipove tijela</span>
                    <span className="loop-text">Za sve dobne skupine</span>
                    <span className="loop-text">Individualni pristup</span>
                    <span className="loop-text">
                      Kondicija za mozak i tijelo
                    </span>
                  </TextLoop>{" "}
                </h2>
                <Social />
                {/* End social slide  */}
              </div>
            </div>
          </div>
        </div>
        {/* End .container */}

        <div className="video-background gray">
          <VideoTag
            autoPlay={`${true}`}
            muted={`${true}`}
            playsInline={`${true}`}
            loop={`${true}`}
            src={vvvideo}
          />
        </div>

        <div className="go-to go-to-next">
          <a href="#about">
            <span></span>
          </a>
        </div>
        {/* End bottom scroll */}
      </section>
      {/* End Home Banner  */}
    </>
  );
};

export default Slider;
