import React from "react";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaPinterestP,
  FaYoutube,
} from "react-icons/fa";
import Impressum from "./Impressum";
import Terms from "./terms";

const SocialShare = [
  {
    Social: <FaFacebookF />,
    link: "https://www.facebook.com/profile.php?id=100074109152201",
  },
  {
    Social: <FaInstagram />,
    link: "https://www.instagram.com/pilatesoblique/?hl=en",
  },
  {
    Social: <FaYoutube />,
    link: "https://youtube.com/channel/UCHXUkPwGXgBqRq7VVSP8xAQ",
  },
];

const Footer = () => {
  return (
    <>
      <div className="row align-items-center">
        <div className="col-md-6 my-2">
          <div className="nav justify-content-center text-center text-md-left justify-content-md-start">
            <Terms />
            <Impressum />
            {SocialShare.map((val, i) => (
              <a key={i} href={`${val.link}`} rel="noreferrer" target="_blank">
                {val.Social}
              </a>
            ))}
          </div>
          {/* End .nav */}
        </div>
        {/* End .col */}

        <div className="col-md-6 my-2 text-center text-md-right">
          <p>
            © {new Date().getFullYear()} copyright{" "}
            <a
              href="https://www.oblique-pilates.com"
              target="_blank"
              rel="noreferrer"
            >
              Pilates Studio Oblique |
            </a>
            <span> Sva prava pridržana || Created by </span>
            <a href="https://www.vely.digital" target="_blank" rel="noreferrer">
              vely.digital
            </a>
          </p>
        </div>
        {/* End .col */}
      </div>
      {/* End .row */}
    </>
  );
};

export default Footer;
